import { useContext, useEffect, useState } from "react";
import {Link, useParams } from "react-router-dom";
import { getUserDetails } from "services/apiServices";
import WrittingPng from 'assets/images/writing.png'
import AppContext from "context/appContext";
import { IoMdArrowRoundBack } from "react-icons/io";
const NotaryDetails = () => {
    let { id } = useParams()
    let context = useContext(AppContext)
    const [details, setDetails] = useState('')
    useEffect(() => {
        const getNotaryInfo = () => {
            getUserDetails(id).then(res => {
                if (res.status == 200) {
                    console.log("notary details", res.data.data)
                    setDetails(res.data.data)
                }
            })
        }
        getNotaryInfo()
    }, [])
    return (
        <>
            <main class="content">
                <div>
                    <a id="btn-toggle" href="#" class="sidebar-toggler break-point-sm">
                        <i class="ri-menu-line ri-xl"></i>
                    </a>
                    <div class="container-fluid">

                        <div class="row">
                        <div class="col-md-12">
                                <div class="clearfix mt-3 mb-2">
                                    <div class="float-start">
                                        <Link to={"/notaries"}>
                                        <h5 class="text-contact fw-bolder"><IoMdArrowRoundBack /> Back</h5>
                                        </Link>                                       
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="clearfix  mb-4">
                                    <div class="float-start">
                                        <h2 class="text-contact fw-bolder">View Notary Details</h2>
                                    </div>
                                    {/* <div class="float-end">
                                        <nav aria-label="breadcrumb">
                                            <ol class="breadcrumb contact-bread">
                                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                                <li class="breadcrumb-item"><a href="#">My Bookings</a></li>
                                                <li class="breadcrumb-item active" aria-current="page">View Booking Details</li>
                                            </ol>
                                        </nav>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="card border-0 manage-usr-text mb-3 text-nowrap">
                                <div class="card-body">
                                    <div class="row">

                                        <div class="col-md-6">
                                            <div class="mb-4 pb-2">
                                                <div class="form-label fw-bolder tex-muted">Name</div>
                                                <div class="fw-bolder">{details.name}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-4 pb-2">
                                                <div class="form-label fw-bolder tex-muted">language</div>
                                                <div class="fw-bolder h5">{details.language}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-4 pb-2">
                                                <div class="form-label fw-bolder tex-muted">email</div>
                                                <div class="fw-bolder h5">{details.email}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-4 pb-2">
                                                <div class="form-label fw-bolder tex-muted">Address</div>
                                                <div class="fw-bolder h5">{details.address?.name}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-4 pb-2">
                                                <div class="form-label fw-bolder tex-muted">Services</div>
                                                {details.service?.map((service) =>
                                                    <div class="fw-bolder h5">{service.service_name}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-4 pb-2">
                                                <div class="form-label fw-bolder tex-muted">Documents</div>
                                                {details.serviceType == "individual" &&
                                                    <div class="row">
                                                        <div class="col-4">
                                                            <div class="card">
                                                                <div class="card-body">
                                                                    <img src={WrittingPng} class="card-img-top" alt="images" style={{ objectFit: "contain", height: "60px" }} />
                                                                </div>
                                                            
                                                                <a href={context.img_uri + details?.commissionDoc} target="_blank" class="stretched-link"></a>
                                                            </div>
                                                        </div>
                                                        <div class="col-4">
                                                            <div class="card">
                                                                <div class="card-body">
                                                                    <img src={WrittingPng} class="card-img-top" alt="images" style={{ objectFit: "contain", height: "60px" }} />
                                                                </div>
                                                                <a href={context.img_uri + details?.omissionInsurance} target="_blank" class="stretched-link"></a>
                                                            </div>
                                                        </div>
                                                        <div class="col-4">
                                                            <div class="card">
                                                                <div class="card-body">
                                                                    <img src={WrittingPng} class="card-img-top" alt="images" style={{ objectFit: "contain", height: "60px" }} />
                                                                </div>
                                                                <a href={context.img_uri + details?.govtIdProof} target="_blank" class="stretched-link"></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {details.serviceType == "business" &&
                                                    <div class="row">
                                                        <div class="col-4">
                                                            <div class="card">
                                                                <div class="card-body">
                                                                    <img src={WrittingPng} class="card-img-top" alt="images" style={{ objectFit: "contain", height: "60px" }} />
                                                                </div>
                                                                <a href={context.img_uri + details.dbaDoc} target="_blank" class="stretched-link"></a>
                                                            </div>
                                                        </div>
                                                        <div class="col-4">
                                                            <div class="card">
                                                                <div class="card-body">
                                                                    <img src={WrittingPng} class="card-img-top" alt="images" style={{ objectFit: "contain", height: "60px" }} />
                                                                </div>
                                                                <a href={context.img_uri + details.ein} target="_blank" class="stretched-link"></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default NotaryDetails;