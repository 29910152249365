import AppContext from "context/appContext";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { editJobService, getServiceById, getStates, } from "services/apiServices";
import TextEditor from "../../utils/text-editor"
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css';
import { formats, modules } from "../../utils/editor-tool-options"

const EditService = () => {
    const context = useContext(AppContext)
    const { register, handleSubmit, formState: { errors }, setValue,control } = useForm()
    const [avatar, setAvatar] = useState('')
    const [serivceStates,setServiceStates]= useState([])
    const [selectedState, setSelectedState] = useState('')
    const [selectedStateCode, setSelectedStateCode] = useState('')
    const [states, setStates] = useState([])
    const [image, setImage] = useState('')
    let navigate = useNavigate()
    let { id } = useParams();

    const handleStateChange = (e) => {
        const selectedStateName = states[e.target.selectedIndex - 1]?.name;
        const selectedStateCode = states[e.target.selectedIndex - 1]?.code;
    
        setSelectedState(selectedStateName);
        setSelectedStateCode(selectedStateCode);
    
        const existingState = serivceStates.find(state => state.state === selectedStateName);
    
        if (existingState) {
            setValue('price', existingState.price);
        } else {
            setValue('price', '');
        }
    }

    const formSubmit = (data) => {
        let obj ={...data,states:serivceStates}
        editJobService(id, obj).then(response => {
            if (response.status == 200) {
                navigate('/services')
                toast.success(response.data.message)
            }
        }).catch(err => {
            toast.error(err.response.data.message)
        })
       
    }


    const handlePriceChange = (e) => {
        if(!selectedState){
            return
        }
        const newPrice = e.target.value.trim();
        const existingIndex = serivceStates.findIndex(state => state.state === selectedState);

        if (existingIndex !== -1) {
            // Update existing state entry
            const updatedServiceStates = [...serivceStates];
            updatedServiceStates[existingIndex] = {
                state: selectedState,
                code: selectedStateCode,
                price: newPrice
            };
            setServiceStates(updatedServiceStates);
        } else {
            // Add new state entry
            setServiceStates(prevStates => [
                ...prevStates,
                {
                    state: selectedState,
                    code: selectedStateCode,
                    price: newPrice
                }
            ]);
        }
    }

    // const imageObj = (e) => {
    //     console.log(e.target.files)
    //     setImage(e.target.files[0])
    //     let file_url = URL.createObjectURL(e.target.files[0])
    //     // console.log(file_url);
    //     setAvatar(file_url)
    // }


    useEffect(() => {
        const GetServiceDetail = () => {
            getServiceById(id).then(response => {
                if (response.status == 200) {
                    setAvatar(context.img_uri + response.data.data.img)
                    setValue('service_name', response.data.data.service_name)
                    setValue('service_description', response.data.data.service_description)
                    setValue('service_points', response.data.data.service_points)
                    setServiceStates(response?.data?.data?.states)
                    // setValue('price', response.data.data.price)
                }
            }).catch(err => {
                toast.error(err.response.data.message)
            })
        }
        const getStatesList = () => {
            getStates().then(res => {
                if (res.status == 200) {
                    setStates(res.data.list);
                }
            })
        }
        getStatesList()
        GetServiceDetail()

    }, []);
    return (
        <>
            <main class="content">
                <div>
                    <a id="btn-toggle" href="#" class="sidebar-toggler break-point-sm">
                        <i class="ri-menu-line ri-xl"></i>
                    </a>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="clearfix mt-3 mb-4">
                                    <div class="float-start">
                                        <h2 class="text-contact fw-bolder">Edit Service</h2>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <div class="card border-0 manage-usr-text mb-5">
                                    <div class="card-body">
                                        <form class="p-4" onSubmit={handleSubmit(formSubmit)}>
                                            <div class="avatar-upload text-center">
                                                {/* <div class="avatar-edit">
                                                    <input type="file" id="ProfilePictureWeb" name="ProfilePictureWeb" accept=".png, .jpg, .jpeg" onChange={(e) => imageObj(e)} />
                                                    <label for="ProfilePictureWeb"></label>
                                                </div> */}

                                                <div class="avatar-preview">
                                                    <img src={avatar || "http://i.pravatar.cc/500?img=7"} class="img-fluid" />
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label for="" class="fw-bolder text-black mb-2">Service Name</label>
                                                    <input type="text" disabled class="form-control py-2" placeholder="Enter Notarty" {...register('service_name', { required: "Please enter the name of service." })} />
                                                </div>
                                                {errors.service_name && <span className='error'>{errors.service_name.message}</span>}
                                            </div>
                                            {/* <div class="col-md-12 mt-2">
                                                <div class="form-group">
                                                    <label for="" class="fw-bolder text-black mb-2">Service Price($)</label>
                                                    <input type="text" disabled class="form-control py-2" placeholder="Enter Price" {...register('price', { required: "Please enter the name of service." })} />
                                                </div>
                                                {errors.price && <span className='error'>{errors.price.message}</span>}
                                            </div> */}
                                            <div class="col-md-12 mt-2">
                                                <div class="form-group">
                                                    <label for="" class="fw-bolder text-black mb-2">Service Description</label>
                                                    <textarea rows={3} class="form-control py-2" {...register('service_description', { required: "Please enter the description of service." })} />
                                                </div>
                                                {errors.service_description && <span className='error'>{errors.service_description.message}</span>}
                                            </div>
                                            <div class="col-md-12 mt-2">
                                            <label for="" class="fw-bolder text-black mb-2">Service Points</label>
                                                <Controller
                                                    name="service_points"
                                                    rules={{ required: "Field is required." }}
                                                    control={control}
                                                    render={(({ field: { onChange, value }, fieldState: { error } }) =>
                                                        <>
                                                            <TextEditor />
                                                            <ReactQuill
                                                                theme="snow"
                                                                value={value}
                                                                onChange={(val) => val === "<p><br></p>" ? onChange('') : onChange(val)}
                                                                placeholder={"Make bullet points here."}
                                                                modules={modules}
                                                                formats={formats}
                                                            />
                                                            {error?.message && <span>{error.message}</span>}
                                                        </>
                                                    )} />
                                            </div>

                                          

                                            <button type="submit" class="btn btn-linear px-5 mt-4">Edit</button>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default EditService;