import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { getJobServices ,getStates,getCities} from "services/apiServices";
import AppContext from "../../context/appContext"

const ServicePricesList = () => {
    const navigate = useNavigate()
    const [services, setServices] = useState([])
    const context = useContext(AppContext)

    useEffect(() => {
        const GetServices = () => {
            getJobServices().then(response => {
                if (response.status == 200) {
                    setServices(response.data.list)
                }
            }).catch(err => {
                toast.error(err.response.data.message)
            })
        }
     GetServices()
     
    }, [])
    return (
        <>
            <main class="content">

                <a id="btn-toggle" href="#" class="sidebar-toggler break-point-sm">
                    <i class="ri-menu-line ri-xl"></i>
                </a>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="clearfix mt-3 mb-4">
                                <div class="float-start">
                                    <h2 class="text-contact fw-bolder">Service</h2>
                                </div>
                            </div>
                        </div>
                    </div>



            <div class="container mt-4">
                <div class="row">
                    {services?.length !== 0 && services.map((service) =>
                        <div class="col-lg-6"  key={service?._id}>
                            <div class="card mb-4 border-0">
                                <div class="card-body p-4">
                                    <div class="row g-0">
                                        <div class="col-md-2">
                                            <img src={context.img_uri + service.img} class="img-fluid rounded-start" alt="icons" />
                                        </div>
                                        <div class="col-md-10">
                                        <Link to={service.service_name !== "Real Estate Transaction" ?`/manage-service-price/${service._id}`:`/manage-real-estate-service/${service._id}`}>
                <h5 className="card-title fw-900 m-img">{service.service_name}</h5>
              </Link>
              <p className="card-text">{service.service_description}</p>

                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                      </div>
                        </div>
                    

                   
                </div>
            </main>
        </>
    );
}

export default ServicePricesList;