import logo from "./logo.svg";
import "./App.css";
import Layout from "layout/layout";
import AppRouting from "routes/routes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminLogin from "components/login";
function App() {
  return (
    <>
      <Layout>
        <AppRouting />
      </Layout>
      <ToastContainer theme="dark" />
    </>
  );
}

export default App;
