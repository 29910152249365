import { useEffect, useRef, useState } from "react";
import AppContext from "./appContext";

const AppState = ({ children }) => {
  const [session, setSession] = useState("");
  //const img_uri = "http://localhost:5003";
    const img_uri = "https://mobilenotary-api.alcax.com";
   //const img_uri = "https://api.mobilenotaryx.ai";

  useEffect(() => {
    if (
      localStorage.getItem("session") &&
      localStorage.getItem("session") !== ""
    ) {
      setSession(localStorage.getItem("session"));
    }
  }, []);
  const values = {
    session,
    setSession,
    img_uri,
  };
  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
};

export default AppState;
