import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getJobServices } from "services/apiServices";
import { FiEdit } from "react-icons/fi";
import { RiEyeFill } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";

const ServicesList = () => {
  const [services, setServices] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {
    const getSerivces = () => {
      getJobServices()
        .then((response) => {
          if (response.status == 200) {
            setServices(response.data.list);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    };
    getSerivces();
  }, []);
  return (
    <>
      <main class="content">
        <a id="btn-toggle" href="#" class="sidebar-toggler break-point-sm">
          <i class="ri-menu-line ri-xl"></i>
        </a>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="clearfix mt-3 mb-4">
                <div class="float-start">
                  <h2 class="text-contact fw-bolder">Manage Services</h2>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="card border-0 manage-usr-text mb-5">
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-first text-nowrap" id="table">
                      <thead>
                        <tr>
                          <th>Sr no.</th>
                          <th>Name</th>
                          <th>Description</th>
                          {/* <th>Phone Number</th>
                                          <th>Account Status</th>*/}
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {services.length !== 0 &&
                          services.map((service, i) => (
                            <tr>
                              <td>{i + 1}</td>
                              <td>{service.service_name}</td>
                              <td>{service.service_description}</td>

                              <td>
                                <div className="d-flex align-items-center">
                                  <FiEdit
                                    onClick={() =>
                                      navigate(`/edit-service/${service._id}`)
                                    }
                                  />
                                  <Link
                                    to={`/service-price-details/${service._id}`}
                                    className="me-2"
                                    title="view"
                                  >
                                    <button className="btn" type="button">
                                      <RiEyeFill />
                                    </button>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ServicesList;
