import React,{useState} from "react";
import { RiAddLine } from "react-icons/ri";
import AddBooking from "./addBooking/AddBooking";

const Header = () => {
    const [showAddBooking,setShowAddBooking] = useState(false);

const handleClose=()=>{
    setShowAddBooking(false)
}

    return ( 
        <>
            <nav class="navbar navbar-expand-lg bg-white sticky-top">
                    <div class="container-fluid">
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <AddBooking showAddBooking={showAddBooking} handleClose={handleClose}/>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <div class="ms-auto">
                                <div class="dropdown">
                                    <button className="btn btn-primary py-1 fw-bolder add-booking" onClick={()=>setShowAddBooking(true)}><RiAddLine /> Add Booking</button>
                                    {/* <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img src="https://www.w3schools.com/howto/img_avatar.png" alt="img-picture" class="img-fluid rounded-circle" width="40" />
                                        <span class="ms-2">Johny Depp</span>
                                    </a>
                                    <ul class="dropdown-menu border-0">
                                        <li><a class="dropdown-item" href="profile.html"><i class="fa fa-user me-2"></i>Profile</a></li>
                                        <li><a class="dropdown-item" href="login.html"><i class="fa fa-sign-out me-2"></i>Logout</a></li>
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
        </>
    );
}
 
export default Header;