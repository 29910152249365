import Icon5 from '../assets/images/icon-5.png'
import Calender from '../assets/images/calendar.svg'
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import Autocomplete from "react-google-autocomplete";
import RealEstateTransaction from '../components/addBooking/realEstate/RealEstate';
import I9Verification from '../components/addBooking/I-9verification/I9Verification';
import OathAdminstration from '../components/addBooking/oathAdminstration/OathAdminstration';
import StandardNotarization from '../components/addBooking/standardNotarization/StandardNotarization';


const ServiceForm = () => {
    const { state } = useLocation()
    console.log(state, moment(state?.selectedDate).format('HH:mm dddd,MMMM DD,YYYY'))
    return (
        <>
            {state?.service_name == "Standard Notarization" ?
                <StandardNotarization state={state} /> :
                state?.service_name == "Real Estate Transaction" ?
                    <RealEstateTransaction state={state} /> :
                    state?.service_name == "I-9 Verification" ?
                        <I9Verification state={state} /> :
                        state?.service_name == "Oath Administration-Sworn Statement" ?
                            <OathAdminstration state={state} /> :
                            <p>No service found</p>
            }
        </>
    );
}

export default ServiceForm;