import Header from "components/header";
import Sidebar from "components/sidebar";
import AppContext from "context/appContext";
import { useContext } from "react";

const Layout = ({ children }) => {
    const context = useContext(AppContext)
    return (
        <>
            <div class="layout has-sidebar fixed-sidebar fixed-header">
                {(context.session !== null && context.session !== "") &&
                    <Sidebar />
                }
                <div class="layout">
                    {(context.session !== null && context.session !== "") &&

                        <Header />

                    }
                    {children}
                </div>
            </div>

        </>
    );
}

export default Layout;