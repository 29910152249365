import { useEffect, useState } from "react";
import { RiDeleteBin3Fill, RiEyeFill, RiForbidFill, RiPencilFill } from "react-icons/ri";
import { FaCheck } from 'react-icons/fa';
import { Link } from "react-router-dom";
import { ImUserMinus } from "react-icons/im";
import { toast } from "react-toastify";
import { getSupport, markAsResolvedSupport, deletedHelpSupport } from "services/apiServices";
import HelpAndSupportDetail from "./HelpAndSupportDetail";
import ReactPaginate from 'react-paginate';


const HelpAndSupport = () => {
    const [supportList, setSupportList] = useState([])
    const [show, setShow] = useState(false)
    const [supportId, setSupportId] = useState('')
    const [paginated, setPaginated] = useState([])
    const [page, setPage] = useState(0)
    const [offset, setOffset] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [pageCount, setPageCount] = useState(0)
    const [search, setSearch] = useState('')

    const helpAndSupportList = () => {
        getSupport().then((response) => {
            if (response.status == 200) {
                setSupportList(response?.data?.data)
                setPageCount(Math.ceil(response?.data?.data?.length / itemsPerPage))
            }
        });
    }

    const handleUpdation = (id) => {
        markAsResolvedSupport(id).then(res => {
            if (res.status == 200) {
                helpAndSupportList()
                toast.success(res?.data?.message)
            }
        }).catch((err) => {
            if (err.response.status == 401) {
                toast.error(err.response.data.message)
            }
            if (err.response.status == 400) {
                toast.error(err.response.data.message)
            }
        })
    }

    const deleteHandler = (id) => {
        deletedHelpSupport(id).then(res => {
            if (res.status == 200) {
                helpAndSupportList()
                toast.success(res?.data?.message)
            }
        }).catch((err) => {
            if (err.response.status == 401) {
                toast.error(err.response.data.message)
            }
            if (err.response.status == 400) {
                toast.error(err.response.data.message)
            }
        })
    }
    const handleSearch = (e) => {
        setSearch(e.target.value)
        setOffset(0)
        setPage(0)
    
      }
    
    const handlePageClick = (e) => {
        setPage(e.selected)
        const newOffset = (e.selected * itemsPerPage);
        setOffset(newOffset)
    }

    const ViewHandler = (id) => {
        setSupportId(id)
        setShow(true)
    }
    const handleClose = () => {
        setShow(false)
    }
    const paginationHandler = () => {
        const endOffset = offset + itemsPerPage;
        let paginated = []
       
        if (search.length > 0) {
            let searchArr = supportList.filter((item) => item?.email?.toLowerCase().includes(search.toLowerCase()) || item?.subject?.toLowerCase().includes(search.toLowerCase()) )
            setPageCount(Math.ceil(searchArr.length / itemsPerPage))
            paginated = searchArr.slice(offset, endOffset)
          }
          else {
            setPageCount(Math.ceil(supportList.length / itemsPerPage))
            paginated = supportList.slice(offset, endOffset)
          }
        setPaginated(paginated)
      }


    useEffect(() => {
        paginationHandler()
      }, [supportList, offset,search]);

    useEffect(() => {
        helpAndSupportList()
    }, [])
    return (
        <>
            <main class="content">
                <div>
                    <a id="btn-toggle" href="#" class="sidebar-toggler break-point-sm">
                        <i class="ri-menu-line ri-xl"></i>
                    </a>
                    {show && (<HelpAndSupportDetail show={show} handleClose={handleClose} supportId={supportId} />)}
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="clearfix mt-3 mb-4">
                                    <div class="float-start">
                                        <h2 class="text-contact fw-bolder">Manage Help & Support</h2>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <div class="card border-0 manage-usr-text mb-5">
                                    <div class="card-body">
                                    <div class="search-user"><label>Search:<input type="search" class="search-input" placeholder="" aria-controls="table" onChange={handleSearch} /></label></div>
                                        <div class="table-responsive">
                                            <table class="table table-first text-nowrap" id="table">
                                                <thead>
                                                    <tr>
                                                        <th>Sr no.</th>
                                                        <th>Email</th>
                                                        <th>Subject</th>
                                                        <th>Description</th>
                                                        <th>Status</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {paginated &&
                                                        paginated?.map((support, i) => (
                                                            <tr>
                                                                <td>{((page + 1) * itemsPerPage - itemsPerPage) + i + 1}</td>
                                                                <td>{support?.email}</td>
                                                                <td>{support?.subject.length > 15 ? support?.subject.substring(0, 15) + '...' : support?.subject}</td>
                                                                <td>{support?.description?.length > 15 ? support?.description.substring(0, 15) + '...' : support?.description}</td>
                                                                <td>{support.status == 'Pending' ? <p style={{ color: "red" }}>{support.status}</p> : <p style={{ color: "green" }}>{support.status}</p>}</td>
                                                                <td>
                                                                    <Link class="me-2" title="view">
                                                                        <button
                                                                            class="btn btn-light"
                                                                            type="button"
                                                                            onClick={() => deleteHandler(support._id)}
                                                                        >
                                                                            <RiDeleteBin3Fill />
                                                                        </button>
                                                                    </Link>
                                                                    <Link
                                                                        class="me-2"
                                                                        title="view"
                                                                    >
                                                                        <button class="btn btn-light" type="button" onClick={() => ViewHandler(support._id)}>
                                                                            <RiEyeFill />
                                                                        </button>
                                                                    </Link>
                                                                    {support?.status == 'Pending' && <button className="btn btn-light  align-items-center" type="button" onClick={() => handleUpdation(support._id)}> <span className='ms-1'>Mark As Resolved</span></button>}
                                                                </td>
                                                            </tr>
                                                        ))}


                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {supportList?.length > 10 &&
                    <ReactPaginate
                        breakLabel="..."
                        breakClassName={'break'}
                        nextLabel="next >"
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        onPageChange={handlePageClick}
                        forcePage={page}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="< previous"

                    />
                }
            </main>
        </>
    );
};



export default HelpAndSupport