import TextEditor from "../../utils/text-editor"
import ReactQuill from "react-quill"
import { formats, modules } from "../../utils/editor-tool-options"
import { Controller, useForm } from "react-hook-form";
import 'react-quill/dist/quill.snow.css';

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { addFaq } from "services/apiServices";
const Faq = () => {
    const { control, register, handleSubmit,formState: { errors } } = useForm()
    const navigate = useNavigate()
    const onSubmit=data=>{
        addFaq(data).then(res=>{
            if(res.status==201){
                toast.success(res.data.message)
                navigate('/faqs')
            }
        }).catch((err) => {
            if (err.response.status == 401) {
                toast.error(err.response.data.message)
                sessionStorage .clear()
                navigate('/')
            }
            if (err.response.status == 400) {
                toast.error(err.response.data.message)
                 sessionStorage .clear()
                navigate('/')
            }
        })
    }
    return (
        <>
            <main class="content">
                <div>
                    <a id="btn-toggle" href="#" class="sidebar-toggler break-point-sm">
                        <i class="ri-menu-line ri-xl"></i>
                    </a>
                    <div class="container-fluid allCards-new">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="clearfix mt-3 mb-4">
                                    <div class="float-start">
                                        <h2 class="text-contact">Faq</h2>
                                    </div>
                                    {/* <div class="float-end">
                                        <nav aria-label="breadcrumb">
                                            <ol class="breadcrumb contact-bread">
                                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                                <li class="breadcrumb-item"><a href="#">Manage Static</a></li>
                                                <li class="breadcrumb-item active" aria-current="page">Faq</li>
                                            </ol>
                                        </nav>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div class="row">

                            <div class="col-md-12">
                                <div class="card">
                                    <div class="card-body">
                                        <div id="summernote">
                                            <form class="form-design" onSubmit={handleSubmit(onSubmit)}>
                                                <div class="mb-4">
                                                    <label>Question</label>
                                                    <div class="input-group">

                                                        <input type="text" class="form-control" placeholder="Write Quetsion" {...register('question', { required: "Questuion is required." })} />
                                                    </div>
                                                    {errors.name && <span className='error'>{errors.name.message}</span>}
                                                </div>
                                                <Controller
                                                    name="answer"
                                                    rules={{ required: "Field is required." }}
                                                    control={control}
                                                    render={(({ field: { onChange, value }, fieldState: { error } }) =>
                                                        <>
                                                            <TextEditor />
                                                            <ReactQuill
                                                                theme="snow"
                                                                value={value}
                                                                onChange={(val) => val === "<p><br></p>" ? onChange('') : onChange(val)}
                                                                placeholder={"Enter your answer."}
                                                                modules={modules}
                                                                formats={formats}
                                                            />
                                                            {error?.message && <span>{error.message}</span>}
                                                        </>
                                                    )} />
                                                    <div className="col-md-2"><button type="submit" class="btn btn-linear btn-block w-100 mt-3">ADD</button></div>
                                            </form>
                                        </div>
                                        {/* <div class="col-md-2">
                                            <a href="#"><button type="button" class="btn btn-linear btn-block w-100 mt-3">Submit</button></a>
                                        </div> */}
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default Faq;