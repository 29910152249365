import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  addFlatPrice,
  addJobService,
  editFlatPrice,
  getCities,
  getFlatPriceByStateName,
  getStates,
} from "services/apiServices";
import Autocomplete from "react-google-autocomplete";
import { json, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import DataTable from "datatables.net-dt";
const AddFlatPrice = () => {
  const navigate = useNavigate();
  const [states, setStates] = useState([]);
  const [stateId, setStateId] = useState("");
  const [cityPrices, setCityPrices] = useState([]);
  const [showCity, setShowCity] = useState([]);
  const [myCityPrices, setMyCityPrices] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [found, setFound] = useState(false);
  const [cities, setCities] = useState({});
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm();

  const handleStateChange = (e) => {
    if (e.target.value === "") {
      return;
    }
    setStateCode(states[e.target.selectedIndex - 1]?.code)
    setShowCity([]);
    const selectedStateName = states[e.target.selectedIndex - 1]?.name;

    setSelectedState(selectedStateName);

    getFlatPriceByStateName(e.target.value)
      .then((response) => {
        if (response?.status === 200) {
           setFound(true)
          setStateId(response?.data?.states?._id);
          const updatedMyCityPrices = response?.data?.states?.cities;
          setMyCityPrices(updatedMyCityPrices);

          const updatedShowCity = cities[e.target.value]?.map((cityName) => {
            const myCityData = updatedMyCityPrices.find(
              (city) => city?.name === cityName
            );

            return {
              name: cityName,
              price: myCityData ? myCityData?.price : "",
            };
          });

          setShowCity(updatedShowCity);
        }
      })
      .catch((err) => {
        const updatedShowCity = cities[e.target.value]?.map((cityName) => {
          return {
            name: cityName,
            price: "",
          };
        });
       
        if(err?.response?.status === 404 && err?.response?.data?.message === "No flat prices found for this state"){
            setFound(false)
        }
        setShowCity(updatedShowCity);
        // toast.error(err?.response?.data?.error);
      });
  };

  const formSubmit = (data) => {

    const updatedMyCityArray = showCity
      .map((item) => {
        const myCityData = myCityPrices.find((city) => city.name === item.name);

        if (myCityData && item.price) {
          return {
            name: myCityData.name,
            price: item.price,
            _id: myCityData._id,
          };
        } else if (item.price) {
          return { name: item.name, price: item.price };
        }

        return null;
      })
      .filter(Boolean);

    data.cities = updatedMyCityArray;
   if(found){
    editFlatPrice(stateId, data)
    .then((response) => {
      if (response.status == 201) {
        toast.success(response?.data?.message);
        navigate("/prices");
      }
    })

    .catch((err) => {
      toast.error(err.response.data.message);
    });
   }else{
    let myState = data.state
    data.state={
        name:myState,
        code:stateCode
    }
    addFlatPrice(data) .then((response) => {
        if (response.status == 201) {
          toast.success(response?.data?.message);
          navigate("/prices");
        }
      })
  
      .catch((err) => {
        toast.error(err.response.data.message);
      });
   }
  };

  const cityPriceChange = (e, i, stateName) => {
    const { value } = e.target;
    const newCityPrices = [...showCity];
    newCityPrices[i] = {
      name: stateName,
      price: e.target.value,
    };
    setShowCity(newCityPrices);
  };

  useEffect(() => {
    const getStatesList = () => {
      getStates().then((res) => {
        if (res.status == 200) {
          setStates(res.data.list);
        }
      });
    };
    const getCitiesList = () => {
      getCities().then((res) => {
        if (res.status == 200) {
          setCities(res.data.data);
        }
      });
    };
    getStatesList();
    getCitiesList();
  }, []);
  return (
    <>
      <main class="content">
        <div>
          <a id="btn-toggle" href="#" class="sidebar-toggler break-point-sm">
            <i class="ri-menu-line ri-xl"></i>
          </a>
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="clearfix mt-3 mb-4">
                  <div class="float-start">
                    <h2 class="text-contact fw-bolder">Add Flat Price</h2>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="card border-0 manage-usr-text mb-5">
                  <div class="card-body">
                    <form class="p-4" onSubmit={handleSubmit(formSubmit)}>
                      <div className="row">
                        <div class="col-md-6">
                          <div class="mb-4">
                            <label for="address" class="form-label ">
                              Please select state
                            </label>
                            {/* <input type="text" class="form-control" id="address" /> */}
                            <select
                              className="py-2 form-select"
                              {...register("state", {
                                required: "Please select the state",
                              })}
                              onChange={handleStateChange}
                            >
                              <option value="">Select State</option>
                              {states &&
                                states.map((state) => (
                                  <option key={state.code} value={state.name}>
                                    {state.name}{" "}
                                  </option>
                                ))}
                            </select>
                          </div>
                          {errors.name && (
                            <span className="error">{errors.name.message}</span>
                          )}
                        </div>

                        <div className="col-md-12">
                          <div class=" flat-table table-responsive">
                            <table
                              class="table table-first text-nowrap"
                              id="myTable"
                            >
                              <thead>
                                <tr>
                                  {/* <th>Sr no.</th> */}
                                  <th>City Name</th>
                                  <th>Price</th>
                                  {/* <th>Phone Number</th>
                                          <th>Account Status</th>*/}
                                  {/* <th>Action</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {showCity?.length !== 0 &&
                                  showCity?.map((item, index) => (
                                    <tr key={item?.name}>
                                      {/* <td>{i + 1}</td> */}
                                      <td>{item.name}</td>
                                      <td className="d-flex align-items-center">
                                        ${" "}
                                        <input
                                          type="number"
                                          class="form-control py-2 ms-2"
                                          value={item?.price}
                                          onChange={(e) =>
                                            cityPriceChange(
                                              e,
                                              index,
                                              item?.name
                                            )
                                          }
                                        />
                                      </td>

                                      {/* <td>
                                                            <FiEdit onClick={()=>navigate(`/edit-service/${service._id}`)}/>
                                                        </td> */}
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <button
                            type="submit"
                            class="btn btn-linear px-5 mt-4"
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default AddFlatPrice;
