import { changeUserStatus } from "services/apiServices";

export const modalSubmit = (type, data, dispatch) => {
  switch (type) {
    case "deactivate-notary":
      return changeUserStatus(data.id, data.status);

      break;

    default:
      break;
  }
};
