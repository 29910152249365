import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import {Link, useParams } from "react-router-dom";
import { getJobDetails } from "services/apiServices";
import WrittingPng from "assets/images/writing.png";
import AppContext from "context/appContext";
import { convertToLocalTime } from "services/service";
import { IoMdArrowRoundBack } from "react-icons/io";

const JobDetails = () => {
  let context = useContext(AppContext);
  let { id } = useParams();
  const [details, setDetails] = useState("");
  useEffect(() => {
    getJobDetails(id).then((response) => {
      if (response.status == 200) {
        console.log("Job Details: ", response.data.data);
        setDetails(response.data.data);
      }
    });
  }, []);
  return (
    <>
      <main class="content">
        <div>
          <a id="btn-toggle" href="#" class="sidebar-toggler break-point-sm">
            <i class="ri-menu-line ri-xl"></i>
          </a>
          <div class="container-fluid">
            <div class="row">
            <div class="col-md-12">
                <div class="clearfix mt-3 mb-2">
                  <div class="float-start">
                    <Link to={"/jobs"}>
                    <h5 className="text-contact fw-bolder"><IoMdArrowRoundBack /> Back</h5>
                    </Link>               
                  </div>
                 
                </div>
              </div>
              <div class="col-md-12">
                <div class="clearfix mt-2 mb-4">
                  <div class="float-start">
                    <h2 class="text-contact fw-bolder"> View Booking Details</h2>
                  </div>
                  {/* <div class="float-end">
                                        <nav aria-label="breadcrumb">
                                            <ol class="breadcrumb contact-bread">
                                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                                <li class="breadcrumb-item"><a href="#">My Bookings</a></li>
                                                <li class="breadcrumb-item active" aria-current="page">View Booking Details</li>
                                            </ol>
                                        </nav>
                                    </div> */}
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-4">
                <div class="card border-0 manage-usr-text h-100 text-nowrap">
                  <div class="card-body">
                    <div class="">
                      <div class="row">
                        <div class="col-md-12">
                          <h4 class="text-black fw-bolder mb-4">
                            Customer Details
                          </h4>
                          <div class="row mb-3">
                            <div class="col-5">
                              <h6 class="fw-bolder mb-0">Name :</h6>
                            </div>
                            <div class="col-7">
                              <h6 class="fw-bolder mb-0 text-black">
                                {details.posted_by?.name}
                              </h6>
                            </div>
                          </div>
                          <div class="row mb-3">
                            <div class="col-5">
                              <h6 class="fw-bolder mb-0">Email :</h6>
                            </div>
                            <div class="col-7">
                              <h6 class="fw-bolder mb-0 text-black">
                                {details.posted_by?.email}
                              </h6>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-5">
                              <h6 class="fw-bolder mb-0">Mobile No. :</h6>
                            </div>
                            <div class="col-7">
                              <h6 class="fw-bolder mb-0 text-black">
                                {details.posted_by?.phoneNumber}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <div class="card border-0 manage-usr-text h-100 text-nowrap">
                  <div class="card-body">
                    <div class="">
                      <div class="row">
                        <div class="col-md-12">
                          <h4 class="text-black fw-bolder mb-4">
                            Notary Details
                          </h4>
                          {details.assigned_to ? (
                            <>
                              <div class="row mb-3">
                                <div class="col-5">
                                  <h6 class="fw-bolder mb-0">Name :</h6>
                                </div>
                                <div class="col-7">
                                  <h6 class="fw-bolder mb-0 text-black">
                                    {details.assigned_to?.name}
                                  </h6>
                                </div>
                              </div>
                              <div class="row mb-3">
                                <div class="col-5">
                                  <h6 class="fw-bolder mb-0">Email :</h6>
                                </div>
                                <div class="col-7">
                                  <h6 class="fw-bolder mb-0 text-black">
                                    {details.assigned_to?.email}
                                  </h6>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-5">
                                  <h6 class="fw-bolder mb-0">Mobile No. :</h6>
                                </div>
                                <div class="col-7">
                                  <h6 class="fw-bolder mb-0 text-black">
                                    {details.assigned_to?.phoneNumber}
                                  </h6>
                                </div>
                              </div>
                            </>
                          ) : (
                            <p
                              class="fw-bolder"
                              style={{ textAlign: "center" }}
                            >
                              Not Assigned
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="card border-0 manage-usr-text h-100">
                  <div class="card-body">
                    <div class="">
                      <div class="row">
                        <div class="col-md-12">
                          <h4 class="text-black fw-bolder mb-4">
                            Booking Details
                          </h4>

                          {/* <div class="row mb-3">
                                                        <div class="col-12">
                                                            <span class="fw-bolder mb-0">Booking ID :</span>
                                                            <span class="fw-bolder mb-0 text-black">{details._id}</span>
                                                        </div>
                                                      */}
                          {/* </div> */}

                          <div class="row mb-3">
                            <div class="col-5">
                              <h6 class="fw-bolder mb-0">Booking ID :</h6>
                            </div>
                            <div class="col-7">
                              <h6 class="fw-bolder mb-0 text-black">
                                {details._id}
                              </h6>
                            </div>
                          </div>
                          <div class="row mb-3">
                            <div class="col-5">
                              <h6 class="fw-bolder mb-0">Date & Time :</h6>
                            </div>
                            <div class="col-7">
                              <h6 class="fw-bolder mb-0 text-black">
                                {moment(
                                  convertToLocalTime(details.schedule_date)
                                ).format("DD MMM YYYY & h:mm A")}
                              </h6>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-5">
                              <h6 class="fw-bolder mb-0">Type of Service :</h6>
                            </div>
                            <div class="col-7">
                              <h6 class="fw-bolder mb-0 text-black">
                                {details.service?.service_name}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-4">
                {/* <div class="col-md-12">
                                    <div class="card border-0 manage-usr-text mb-5">
                                        <div class="card-body">
                                            <div class="p-3">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <h4 class="text-black fw-bolder mb-4">Booking Details</h4>
                                                        <div class="row mb-3">
                                                            <div class="col-5">
                                                                <h6 class="fw-bolder mb-0">Booking ID :</h6>
                                                            </div>
                                                            <div class="col-5">
                                                                <h6 class="fw-bolder mb-0 text-black">#6754343435</h6>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-3">
                                                            <div class="col-5">
                                                                <h6 class="fw-bolder mb-0">Date & Time :</h6>
                                                            </div>
                                                            <div class="col-5">
                                                                <h6 class="fw-bolder mb-0 text-black">12 Dec 2023 & 05:30pm</h6>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-3">
                                                            <div class="col-5">
                                                                <h6 class="fw-bolder mb-0">Type of Service :</h6>
                                                            </div>
                                                            <div class="col-5">
                                                                <h6 class="fw-bolder mb-0 text-black">Passport ID</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                {/* <div class="col-md-12">

                                    <div class="card border-0 table-card">
                                        <div class="card-body">

                                            <ul class="nav nav-pills custom_Pills my-3" id="pills-tab" role="tablist">
                                                <li class="nav-item" role="presentation">
                                                    <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Customer</button>
                                                </li>
                                                <li class="nav-item" role="presentation">
                                                    <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Notary</button>
                                                </li>

                                            </ul>

                                            <div class="tab-content" id="pills-tabContent">
                                                <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                                                    <div class="card border-0 table-card">
                                                        <div class="card-body">
                                                            <div class="container align-items-center">
                                                                <div class="row">
                                                                    <div class="col-md-4">
                                                                        <div class="mb-4 pb-2">
                                                                            <h6 class="fw-bolder">Name</h6>
                                                                            <h6 class="fw-bolder text-black">Johnny Depp</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-4">
                                                                        <div class="mb-4 pb-2">
                                                                            <h6 class="fw-bolder">Phone Number</h6>
                                                                            <h6 class="fw-bolder text-black">674344575234324</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-4">
                                                                        <div class="mb-4 pb-2">
                                                                            <h6 class="fw-bolder">Are you the singer?</h6>
                                                                            <h6 class="fw-bolder text-black">Yes</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-4">
                                                                        <div class="mb-4 pb-2">
                                                                            <h6 class="fw-bolder">Have you reviewed our pricing structure on the website?</h6>
                                                                            <h6 class="fw-bolder text-black">Yes</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-4">
                                                                        <div class="mb-4 pb-2">
                                                                            <h6 class="fw-bolder">Are the documents related to a Real Estate Transaction?</h6>
                                                                            <h6 class="fw-bolder text-black">Yes</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-4">
                                                                        <div class="mb-4 pb-2">
                                                                            <h6 class="fw-bolder">Please provide the type/name of document(s) needing to be notarized.</h6>
                                                                            <h6 class="fw-bolder text-black">Hello</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
                                                    <div class="card border-0 table-card">
                                                        <div class="card-body">

                                                            <div class="container align-items-center">
                                                                <div class="row">
                                                                    <div class="col-md-4">
                                                                        <div class="mb-4 pb-2">
                                                                            <h6 class="fw-bolder">Price</h6>
                                                                            <h6 class="fw-bolder text-black">$3420.00</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-4">
                                                                        <div class="mb-4 pb-2">
                                                                            <h6 class="fw-bolder">Note</h6>
                                                                            <h6 class="fw-bolder text-black">Lorem, ipsum dolor sit, amet consectetur adipisicing elit. Laborum, recusandae temporibus hic quisquam, eveniet labore necessitatibus harum ducimus error quis distinctio tenetur, repudiandae ullam, sunt blanditiis pariatur magnam asperiores. Autem?</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-4">
                                                                        <div class="mb-4 pb-2">
                                                                            <h6 class="fw-bolder">Documents</h6>
                                                                            <h6 class="fw-bolder text-black">Yes</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>

                                        </div>

                                    </div>


                                </div> */}
                <div class="col-md-12">
                  <div class="card border-0 manage-usr-text mb-5">
                    <div class="card-body">
                      <div class="p-3">
                        <Tabs
                          defaultActiveKey="customer"
                          id="uncontrolled-tab-example"
                          className="mb-3 new-tab"
                        >
                          <Tab eventKey="customer" title="Customer">
                            <div class="card border-0 table-card">
                              <div class="card-body">
                                <div class="container align-items-center">
                                  {/* <div class="row">
                                                            <div class="col-md-4">
                                                                <div class="mb-4 pb-2">
                                                                    <h6 class="fw-bolder">Name</h6>
                                                                    <h6 class="fw-bolder text-black">Johnny Depp</h6>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <div class="mb-4 pb-2">
                                                                    <h6 class="fw-bolder">Phone Number</h6>
                                                                    <h6 class="fw-bolder text-black">674344575234324</h6>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <div class="mb-4 pb-2">
                                                                    <h6 class="fw-bolder">Are you the singer?</h6>
                                                                    <h6 class="fw-bolder text-black">Yes</h6>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <div class="mb-4 pb-2">
                                                                    <h6 class="fw-bolder">Have you reviewed our pricing structure on the website?</h6>
                                                                    <h6 class="fw-bolder text-black">Yes</h6>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <div class="mb-4 pb-2">
                                                                    <h6 class="fw-bolder">Are the documents related to a Real Estate Transaction?</h6>
                                                                    <h6 class="fw-bolder text-black">Yes</h6>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <div class="mb-4 pb-2">
                                                                    <h6 class="fw-bolder">Please provide the type/name of document(s) needing to be notarized.</h6>
                                                                    <h6 class="fw-bolder text-black">Hello</h6>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                  <div class="row">
                                    <div class="col-md-4">
                                      <div class="mb-4 pb-2">
                                        <div class="form-label small tex-muted">
                                          Name
                                        </div>
                                        <div class="fw-bolder">
                                          {details.name}
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="mb-4 pb-2">
                                        <div class="form-label small tex-muted">
                                          Email
                                        </div>
                                        <div class="fw-bolder h5">
                                          {details.email}
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="mb-4 pb-2">
                                        <div class="form-label small tex-muted">
                                          State
                                        </div>
                                        <div class="fw-bolder h5">
                                          {details.state}
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="mb-4 pb-2">
                                        <div class="form-label small tex-muted">
                                          City
                                        </div>
                                        <div class="fw-bolder h5">
                                          {details.city}
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="mb-4 pb-2">
                                        <div class="form-label small tex-muted">
                                          Address the Notary will be traveling
                                          to?
                                        </div>
                                        <div class="fw-bolder h5">
                                          {details.location?.name}
                                        </div>
                                      </div>
                                    </div>
                                    {details.service?.service_name ==
                                      "Standard Notarization" && (
                                      <>
                                        <div class="col-md-4">
                                          <div class="mb-4 pb-2">
                                            <div class="form-label small tex-muted">
                                              Phone Number
                                            </div>
                                            <div class="fw-bolder h5">
                                              {details.phoneNumber}
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-md-4">
                                          <div class="mb-4 pb-2">
                                            <div class="form-label small tex-muted">
                                              Are you the singer?
                                            </div>
                                            <div class="fw-bolder h5">
                                              {details.are_you_signer}
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-md-4">
                                          <div class="mb-4 pb-2">
                                            <div class="form-label small tex-muted">
                                              If no, please state your
                                              relationship to the signer?
                                            </div>
                                            <div class="fw-bolder h5">
                                              {details.relation_with_signer}
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-md-4">
                                          <div class="mb-4 pb-2">
                                            <div class="form-label small tex-muted">
                                              Have you reviewed our pricing and
                                              cancellation disclaimers structure
                                              on the website?
                                            </div>
                                            <div class="fw-bolder h5">
                                              {
                                                details.reviewed_our_pricing_structure
                                              }
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-md-4">
                                          <div class="mb-4 pb-2">
                                            <div class="form-label small tex-muted">
                                              Are the documents related to a
                                              Real Estate Transaction?
                                            </div>
                                            <div class="fw-bolder h5">
                                              {
                                                details.docs_realated_to_real_estate_transaction
                                              }
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-md-4">
                                          <div class="mb-4 pb-2">
                                            <div class="form-label small tex-muted">
                                              Please provide the type/name of
                                              document(s) needing to be
                                              notarized.
                                            </div>
                                            <div class="fw-bolder h5">
                                              {details.doc_need_to_be_notarized}
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-md-4">
                                          <div class="mb-4 pb-2">
                                            <div class="form-label small tex-muted">
                                              Does the person needing the
                                              notarization have a valid, NOT
                                              expired government issued ID such
                                              as Drivers License or Passport?
                                              (This is needed to perform the
                                              notarization
                                            </div>
                                            <div class="fw-bolder h5">
                                              {
                                                details.does_person_need_valid_govt_id
                                              }
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-md-4">
                                          <div class="mb-4 pb-2">
                                            <div class="form-label small tex-muted">
                                              Does the document being notarized
                                              require additional witnesses not
                                              including the notary?
                                            </div>
                                            <div class="fw-bolder h5">
                                              {details.required_witness}
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                    {details.service?.service_name ==
                                      "Real Estate Transaction" && (
                                      <>
                                        <div class="col-md-4">
                                          <div class="mb-4 pb-2">
                                            <div class="form-label small tex-muted">
                                              Are you the singer?
                                            </div>
                                            <div class="fw-bolder h5">
                                              {details.are_you_signer}
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-md-4">
                                          <div class="mb-4 pb-2">
                                            <div class="form-label small tex-muted">
                                              If no, please state your
                                              relationship to the signer?
                                            </div>
                                            <div class="fw-bolder h5">
                                              {details.relation_with_signer}
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-md-4">
                                          <div class="mb-4 pb-2">
                                            <div class="form-label small tex-muted">
                                              What type of Real Estate
                                              Transaction is this?
                                            </div>
                                            <div class="fw-bolder h5">
                                              {
                                                details.type_of_real_estate_transaction
                                              }
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-md-4">
                                          <div class="mb-4 pb-2">
                                            <div class="form-label small tex-muted">
                                              Have you reviewed our pricing and
                                              cancellation disclaimers structure
                                              on the website?
                                            </div>
                                            <div class="fw-bolder h5">
                                              {
                                                details.reviewed_our_pricing_structure
                                              }
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-md-4">
                                          <div class="mb-4 pb-2">
                                            <div class="form-label small tex-muted">
                                              Does the document being notarized
                                              require additional witnesses not
                                              including the notary?
                                            </div>
                                            <div class="fw-bolder h5">
                                              {details.required_witness}
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}

                                    {/* OAuth  */}
                                    {details.service?.service_name ==
                                      "Oath Administration-Sworn Statement" && (
                                      <>
                                        <div class="col-md-4">
                                          <div class="mb-4 pb-2">
                                            <div class="form-label small tex-muted">
                                              Are you the person giving the
                                              sworn statement?
                                            </div>
                                            <div class="fw-bolder h5">
                                              {
                                                details.are_you_giving_sworn_statement
                                              }
                                            </div>
                                          </div>
                                        </div>

                                        <div class="col-md-4">
                                          <div class="mb-4 pb-2">
                                            <div class="form-label small tex-muted">
                                              If no, please state your relation
                                              to the person giving the sworn
                                              statement
                                            </div>
                                            <div class="fw-bolder h5">
                                              {
                                                details.relation_with_person_giving_sworn_statement
                                              }
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                    {/* i-9 */}
                                    {details.service?.service_name ==
                                      "I-9 Verification" && (
                                      <>
                                        <div class="col-md-4">
                                          <div class="mb-4 pb-2">
                                            <div class="form-label small tex-muted">
                                              Does the signer have the proper
                                              original identification found in
                                              the right section on the I-9
                                              Verification form?
                                            </div>
                                            <div class="fw-bolder h5">
                                              {
                                                details.have_the_proper_original_identification
                                              }
                                            </div>
                                          </div>
                                        </div>

                                        <div class="col-md-4">
                                          <div class="mb-4 pb-2">
                                            <div class="form-label small tex-muted">
                                              Have you reviewed our pricing and
                                              cancellation disclaimers structure
                                              on the website?
                                            </div>
                                            <div class="fw-bolder h5">
                                              {
                                                details.reviewed_our_pricing_structure
                                              }
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab>
                          <Tab eventKey="notary" title="Notary">
                            <div class="card border-0 table-card">
                              <div class="card-body">
                                {details.assigned_to ? (
                                  <div class="container align-items-center">
                                    <div class="row">
                                      <div class="col-md-4">
                                        <div class="mb-4 pb-2">
                                          <h6 class="fw-bolder">Price</h6>
                                          <h6 class="fw-bolder text-black">
                                            $
                                            {details.marked_as_completed == 1
                                              ? Number(details.state_price) +
                                                Number(details.notary_charge)
                                              : details.marked_as_completed == 0
                                              ? Number(details.state_price)
                                              : details.marked_as_completed == 2
                                              ? "NIL."
                                              : ""}
                                          </h6>
                                        </div>
                                      </div>
                                      <div class="col-md-4">
                                        <div class="mb-4 pb-2">
                                          <h6 class="fw-bolder">Note</h6>
                                          <h6 class="fw-bolder text-black">
                                            {details.note}
                                          </h6>
                                        </div>
                                      </div>
                                      <div class="col-md-4">
                                        <div class="mb-4 pb-2">
                                          <h6 class="fw-bolder">Documents</h6>
                                          {/* <h6 class="fw-bolder text-black">Yes</h6> */}
                                          {details.documents?.length !== 0
                                            ? details.documents?.map((doc) => (
                                                <div class="row">
                                                  <div class="col-4">
                                                    <div class="card">
                                                      <div class="card-body">
                                                        <img
                                                          src={WrittingPng}
                                                          class="card-img-top"
                                                          alt="images"
                                                          style={{
                                                            objectFit:
                                                              "contain",
                                                            height: "60px",
                                                          }}
                                                        />
                                                      </div>
                                                      <a
                                                        href={
                                                          context.img_uri + doc
                                                        }
                                                        target="_blank"
                                                        class="stretched-link"
                                                      ></a>
                                                    </div>
                                                  </div>
                                                </div>
                                              ))
                                            : "No Documents"}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <p
                                    className="fw-bolder"
                                    style={{ textAlign: "center" }}
                                  >
                                    Not Assigned
                                  </p>
                                )}
                                <div class="container align-items-center">
                                  <div className="row">
                                  <div class="col-md-4">
                                      <div class="mb-4 pb-2">
                                        <h6 class="fw-bolder">Confirmed</h6>
                                        <h6 class="fw-bolder text-black">
                                          {console.log('details--->',details)}
                                          {details.confirm_out
                                            ? moment(details?.confirm_out).format(
                                                "DD MMM YYYY & h:mm A"
                                              )
                                            : "NIL"}
                                        </h6>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="mb-4 pb-2">
                                        <h6 class="fw-bolder">Arrived</h6>
                                        <h6 class="fw-bolder text-black">
                                          
                                          {details.clock_in
                                            ? moment(details.clock_in).format(
                                                "DD MMM YYYY & h:mm A"
                                              )
                                            : "NIL"}
                                        </h6>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="mb-4 pb-2">
                                        <h6 class="fw-bolder">Completed</h6>
                                        <h6 class="fw-bolder text-black">
                                          {details.clock_out
                                            ? moment(details.clock_out).format(
                                                "DD MMM YYYY & h:mm A"
                                              )
                                            : "NIL"}
                                        </h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default JobDetails;
