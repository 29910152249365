import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { getJobServices, getStates, getCities } from "services/apiServices";
import AppContext from "../../../context/appContext";
import moment from "moment";

const BookingJob = () => {
  const [services, setServices] = useState([]);
  const context = useContext(AppContext);
  const navigate = useNavigate();

  const handleBookings = (type, service) => {
    if (type == "immediate") {
      const date = moment().add(1, "hour").toISOString();
      service.selectedDate = date;
      service.jobType = "immediate";
      navigate("/service-form", { state: service });
    }
    if (type == "schedule") {
      service.jobType = "schedule";
      navigate(`/service/${service.service_name}`, { state: service });
    }
  };

  useEffect(() => {
    const GetServices = () => {
      getJobServices()
        .then((response) => {
          if (response.status == 200) {
            setServices(response.data.list);
          }
        })
        .catch((err) => {
          console.log("err-->", err);
          toast.error(err.response.data.message);
        });
    };
    GetServices();
  }, []);
  return (
    <main class="content">
      <a id="btn-toggle" href="#" class="sidebar-toggler break-point-sm">
        <i class="ri-menu-line ri-xl"></i>
      </a>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="clearfix mt-3 mb-4">
              <div class="float-start">
                <h2 class="text-contact fw-bolder">Select Service</h2>
              </div>
            </div>
          </div>
          {services?.length !== 0 &&
            services.map((service) => (
              <div className="col-lg-6" key={service?._id}>
                <div className="card mb-4 border-0">
                  <div className="card-body p-4">
                    <div className="row g-0">
                      <div className="col-md-2">
                        <img
                          src={context.img_uri + service.img}
                          class="img-fluid rounded-start"
                          alt="icons"
                        />
                      </div>
                      <div className="col-md-10">
                          <h5 className="card-title fw-900 m-img">
                            {service.service_name}
                          </h5>
                        <p className="card-text">
                          {service.service_description}
                        </p>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                            className="btn-books"
                          >
                            Book Now
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={(e) =>
                                handleBookings("immediate", service)
                              }
                            >
                              Immediate
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) =>
                                handleBookings("schedule", service)
                              }
                            >
                              Schedule
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </main>
  );
};

export default BookingJob;
