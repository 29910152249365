const Dashboard = () => {
    return (
        <>
            
                <main class="content">
                    <div>
                        <a id="btn-toggle" href="#" class="sidebar-toggler break-point-sm">
                            <i class="ri-menu-line ri-xl"></i>
                        </a>
                        <div class="container-fluid allCards-new">
                            <div class="row my-3 mb-12">
                                <div class="col-md-12">
                                    <h4 class="fw-bolder text-black">Dashboard</h4>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-lg-4  mains-dashboards">
                                    <div class="card border-0 position-ab card-1">
                                        <div class="row mx-0">
                                            <div class="col-3 d-flex justify-content-center">
                                                <div class="card-header border-0 p-0">
                                                    <i class="fa-solid fa-user-tie size"></i>
                                                </div>
                                            </div>

                                            <div class="col-9">

                                                <h2 class="fw-bolder">231</h2>
                                                <h5 class="fw-bolder">Total Users</h5>
                                                <p class="mb-0 text-muted">This is dummy text.</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-lg-4  mains-dashboards">
                                    <div class="card border-0 position-ab card-1">
                                        <div class="row mx-0">
                                            <div class="col-3 d-flex justify-content-center">
                                                <div class="card-header border-0 p-0">
                                                    <i class="ri-wallet-2-fill"></i>
                                                </div>
                                            </div>

                                            <div class="col-9">

                                                <h2 class="fw-bolder">231</h2>
                                                <h5 class="fw-bolder">Total Transactions</h5>
                                                <p class="mb-0 text-muted">This is dummy text.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <div class="overlay"></div>
            
        </>
    );
}

export default Dashboard;