import { FaCheck, FaTimes } from "react-icons/fa";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import {
  RestrictPopUp,
  ApproveTransaction,
} from "../../utils/restrictionPopUp";
import { retrieveNotaryTransactions } from "services/apiServices";

const NotaryTransaction = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [paginated, setPaginated] = useState([]);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [search, setSearch] = useState("");

  const ModelTransaction = () => {
    retrieveNotaryTransactions()
      .then((res) => {
        if (res.status == 200) {
          setPageCount(Math.ceil(res.data.data.length / itemsPerPage));
          setUsers(res.data.data);
        }
      })
      .catch((err) => {
        if (err.response.status == 401) {
          toast.error(err.response.data.message);
          localStorage.clear();
          navigate("/");
        }
        if (err.response.status == 400) {
          toast.error(err.response.data.message);
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setOffset(0);
    setPage(0);
  };

  const paginationHandler = () => {
    const endOffset = offset + itemsPerPage;
    let paginated = [];
    if (search.length > 0) {
      let searchArr = users.filter((item) =>
        item.receiver.name.toLowerCase().includes(search.toLowerCase())
      );
      setPageCount(Math.ceil(searchArr.length / itemsPerPage));
      paginated = searchArr.slice(offset, endOffset);
    } else {
      setPageCount(Math.ceil(users.length / itemsPerPage));
      paginated = users.slice(offset, endOffset);
    }
    setPaginated(paginated);
  };

  const handlePageClick = (e) => {
    setPage(e.selected);
    const newOffset = e.selected * itemsPerPage;
    setOffset(newOffset);
  };

  const handleTransactionStatus = (status, transactionId) => {
    ApproveTransaction(status, transactionId, ModelTransaction);
  };

  useEffect(() => {
    ModelTransaction();
  }, []);

  useEffect(() => {
    paginationHandler();
  }, [users, offset, search]);

  useEffect(() => {
  }, [users]);

  return (
    <>
      <main class="content">
        <a id="btn-toggle" href="#" class="sidebar-toggler break-point-sm">
          <i class="ri-menu-line ri-xl"></i>
        </a>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="clearfix mt-3 mb-4">
                <div class="float-start">
                  <h2 class="text-contact">Manage Notary Transactions</h2>
                </div>
                <div class="float-end">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb contact-bread">
                      <li class="breadcrumb-item">
                        <a href="#">Home</a>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        Model Transactions
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="card border-0 manage-usr-text mb-5">
                <div class="card-body">
                  <div className="search-user input-group mb-3 justify-content-end">
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Search"
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                      onChange={handleSearch}
                      style={{ maxWidth: "300px" }} // Limiting the maximum width of the search box
                    />
                  </div>

                  <div class="table-responsive">
                    <table class="table table-first text-nowrap" id="table">
                      <thead>
                        <tr>
                          <th>Sr no.</th>
                          <th>Name</th>
                          <th>Image</th>
                          <th>Email</th>
                          {/* <th>Gender</th> */}
                          <th> Transaction Amount </th>
                          <th> Status</th>
                          <th class="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paginated &&
                          paginated.map((user, index) => (
                            <tr key={index}>
                              <td>
                                {(page + 1) * itemsPerPage -
                                  itemsPerPage +
                                  index +
                                  1}
                              </td>                         
                              <td> {user?.receiver?.name}</td>
                              <td>
                                <img
                                  src={
                                    process.env.REACT_APP_IMAGE_URI +
                                    user?.receiver?.avatar
                                  }
                                  alt="image"
                                  width="40"
                                  height="40"
                                  class="rounded-circle me-3"
                                />
                              </td>
                              <td>{user?.receiver?.email}</td>
                              {/* <td>{user?.receiver?.gender}</td> */}
                              <td>{user?.txn_amount}</td>
                              <td>{user?.status}</td>
                              <td align="center">
                                {user?.status === "Pending" ? (
                                  <div class="button-group">
                                    <button
                                      class="btn btn-success btn-sm"
                                      type="button"
                                      onClick={() =>
                                        handleTransactionStatus(
                                          "Approved",
                                          user._id
                                        )
                                      }
                                    >
                                      <FaCheck /> Approve
                                    </button>
                                    <button
                                      class="btn btn-light btn-sm ms-2"
                                      type="button"
                                      onClick={() =>
                                        handleTransactionStatus(
                                          "Rejected",
                                          user._id
                                        )
                                      }
                                    >
                                      <FaTimes /> Reject
                                    </button>
                                  </div>
                                ) : (
                                  <span
                                    class={
                                      user.status == "Approved"
                                        ? "text-success"
                                        : "text-danger"
                                    }
                                  >
                                    {user.status}
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    {paginated.length == 0 && (
                      <span class="no-data">
                        <p>No Data Found</p>
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {users.length > 10 && (
          <ReactPaginate
            breakLabel="..."
            breakClassName={"break"}
            nextLabel="next >"
            containerClassName={"pagination"}
            activeClassName={"active"}
            onPageChange={handlePageClick}
            forcePage={page}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< previous"
          />
        )}
      </main>
    </>
  );
};

export default NotaryTransaction;
