import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
//import LoadingAction from "../../../../components/Loader";
import { addBooking } from "services/apiServices";

const buttonWidth = {
  width: "100%",
};

const AddBooking = (props) => {
  const { showAddBooking, handleClose } = props;
  const navigate = useNavigate();
  const [inputField, setInputField] = useState({
    name: "",
    email: "",
    phoneNumber: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    inputField["role"] = "admin"
    addBooking(inputField).then((res) => {
      if (res.status === 200) {
        toast.success(res?.data?.data?.message)
        localStorage.setItem('addBooking',JSON.stringify(res?.data?.data))
        handleClose();
        navigate("booking-service");
        setInputField({
          name: "",
          email: "",
          phoneNumber: "",
        })
      }
    }).catch((err) => {
      toast.error(err?.response?.data?.message)
    })

  };
  const handleChange = (e) => {
    setInputField({ ...inputField, [e.target.name]: e.target.value });
  };

  return (
    <Modal show={showAddBooking} onHide={handleClose} centered size="md">
      <Modal.Header closeButton={true} className="border-0 mt-4 pt-0 pb-2">
        <div className="d-flex justify-content-center w-100">
          <Modal.Title className="h5">Create Job</Modal.Title>
        </div>
      </Modal.Header>
      {/* {showLoader && <LoadingAction />} */}
      <Modal.Body className="p-4">
        <form onSubmit={handleSubmit} className="form-login n">
          <div className="mb-4">
            <label className="form-label">Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter User Name"
              name="name"
              value={inputField.name}
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <label className="form-label">Email</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter User Email"
              name="email"
              value={inputField.email}
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <label className="form-label">Phone Number</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Phone Number "
              name="phoneNumber"
              value={inputField.phoneNumber}
              onChange={handleChange}
            />
          </div>
          <div className="text-center ">
            <button
              type="submit"
              className="btn btn-primary py-2 fw-bolder update-class"
              style={buttonWidth}
            >
              Next
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddBooking;
