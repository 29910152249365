import { Controller, useForm } from "react-hook-form";
import TextEditor from "../utils/text-editor";
import ReactQuill from "react-quill";
import { formats, modules } from "../utils/editor-tool-options";

import { toast } from "react-toastify";
import { useEffect } from "react";
import { addStaticContent, editStaticContent, getStaticContent } from "services/apiServices";

const Privacy = () => {
    const { control, handleSubmit, formState: { errors }, watch, setValue ,getValues} = useForm()

    const onSubmit = (data) => {
        data['type']="privacy"
        editStaticContent(data).then(res => {
            if (res.status == 200) {
                toast.success('content updated successfully.')
                getContent()
            }
        })
    }

    const getContent = () => {
        getStaticContent(3).then(res => {
            if (res.status == 200) {
                if(res.data.data!==null){
                    setValue('content', res.data.data.content)
                }
            }
        })
    }

    useEffect(() => {
        getContent()
    }, []);
    return (
        <>
            <main class="content">
                <div>
                    <a id="btn-toggle" href="#" class="sidebar-toggler break-point-sm">
                        <i class="ri-menu-line ri-xl"></i>
                    </a>
                    <div class="container-fluid allCards-new">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="clearfix mt-3 mb-4">
                                    <div class="float-start">
                                        <h2 class="text-contact">Privacy Policy</h2><h5>(For Web And Mobile)</h5>
                                    </div>
                                    {/* <div class="float-end">
                                        <nav aria-label="breadcrumb">
                                            <ol class="breadcrumb contact-bread">
                                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                                <li class="breadcrumb-item"><a href="#">Manage Static</a></li>
                                                <li class="breadcrumb-item active" aria-current="page">Faq</li>
                                            </ol>
                                        </nav>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div class="row">

                            <div class="col-md-12">
                                <div class="card">
                                    <div class="card-body">
                                        <div id="summernote">
                                            <form class="form-design" onSubmit={handleSubmit(onSubmit)}>
                                                <Controller
                                                    name="content"
                                                    rules={{ required: "Field is required." }}
                                                    control={control}
                                                    render={(({ field: { onChange, value }, fieldState: { error } }) =>
                                                        <>
                                                            <TextEditor />
                                                            <ReactQuill
                                                                theme="snow"
                                                                value={value}
                                                                onChange={(val) => val === "<p><br></p>" ? onChange('') : onChange(val)}
                                                                placeholder={"Enter Content."}
                                                                modules={modules}
                                                                formats={formats}
                                                            />
                                                            {error?.message && <span className="err-msg">{error.message}</span>}
                                                        </>
                                                    )} />
                                                
                                                    <div className="col-md-2"><button type="submit" class="btn btn-linear btn-block w-100 mt-3">Submit</button></div>
                                                
                                            </form>
                                        </div>
                                        {/* <div class="col-md-2">
                                            <a href="#"><button type="button" class="btn btn-linear btn-block w-100 mt-3">Submit</button></a>
                                        </div> */}
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default Privacy;