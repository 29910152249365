import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from 'react-bootstrap/Form';
import { toast } from "react-toastify";
import { helpAndSupportDetail } from "services/apiServices";
// import LoadingAction from "components/common/LoadingAction";


const buttonWidth = {
    width: "100%",
};

const HelpAndSupportDetail = (props) => {
    const { show, handleClose, supportId } = props;
    const [helpDetail,setHelpDetail] = useState('')

    const supportDetail = (id) =>{
        helpAndSupportDetail(id).then((res)=>{
            if(res?.status==200){
                setHelpDetail(res?.data?.data)
            }
        })
    }
   
const handleSubmit = () =>{
    handleClose()
}
useEffect(()=>{
    supportDetail(supportId)
},[supportId])

    return (
        <Modal show={show} onHide={handleClose} centered size="md">
            <Modal.Header closeButton={true} className="border-0 mt-4 pt-0 pb-2">
                <div className="d-flex justify-content-center w-100">
                    <Modal.Title className="h5">View Help & Support</Modal.Title>
                </div>
            </Modal.Header>
            {/* {showLoader && <LoadingAction />} */}
            <Modal.Body className="p-4">
                <form onSubmit={handleSubmit} className="form-login n">
                    <div className="mb-4">
                        <label className="form-label">Email</label>
                        <p>{helpDetail?.email}</p>
                    </div>
                    <div className="mb-4">
                        <label className="form-label">Subject</label>
                        <p>{helpDetail?.subject}</p>
                    </div>
                    <div className="mb-4">
                        <label className="form-label">Description</label>
                        <p>{helpDetail?.description}</p>
                    </div>
                   
                    <div className="text-center ">
                        <button
                            type="submit"
                            className="btn btn-primary py-2 fw-bolder update-class"
                            style={buttonWidth}
                        >
                            close
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};



export default HelpAndSupportDetail