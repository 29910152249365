import { useEffect, useState } from "react";
import { RiDeleteBin3Fill, RiEyeFill, RiForbidFill } from "react-icons/ri";
import { getJobTransactions } from "services/apiServices";
import ReactPaginate from "react-paginate";

const JobTransactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [paginated, setPaginated] = useState([]);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [search, setSearch] = useState("");


  const paginationHandler = () => {
    const endOffset = offset + itemsPerPage;
    let paginated = [];
    if (search.length > 0) {
      let searchArr = transactions.filter((item) =>
        item.sender?.name.toLowerCase().includes(search.toLowerCase()) || item.notary?.name.toLowerCase().includes(search.toLowerCase())
      );
      setPageCount(Math.ceil(searchArr.length / itemsPerPage));
      paginated = searchArr.slice(offset, endOffset);
    } else {
      setPageCount(Math.ceil(transactions.length / itemsPerPage));
      paginated = transactions.slice(offset, endOffset);
    }
    setPaginated(paginated);
  };

  const handlePageClick = (e) => {
    setPage(e.selected);
    const newOffset = e.selected * itemsPerPage;
    setOffset(newOffset);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setOffset(0);
    setPage(0);
  };
  useEffect(() => {
    const getTransactions = () => {
      getJobTransactions().then((response) => {
        if (response.status == 200) {
          setTransactions(response.data.data);
          setPageCount(response.data.data.length/itemsPerPage)
        }
      });
    };
    getTransactions();
  }, []);

  useEffect(() => {
    paginationHandler();
  }, [transactions, offset,search]);
  return (
    <>
      <main class="content">
        <div>
          <a id="btn-toggle" href="#" class="sidebar-toggler break-point-sm">
            <i class="ri-menu-line ri-xl"></i>
          </a>
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="clearfix mt-3 mb-4">
                  <div class="float-start">
                    <h2 class="text-contact fw-bolder">Manage Transactions</h2>
                  </div>
                  <div class="float-end">
                    <nav aria-label="breadcrumb">
                      <ol class="breadcrumb contact-bread">
                        <li class="breadcrumb-item">
                          <a href="#">Home</a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                          Manage Transactions
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="card border-0 manage-usr-text mb-5">
                  <div class="card-body">
                  <div className="search-user input-group mb-3 justify-content-end">
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Search"
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                      onChange={handleSearch}
                      style={{ maxWidth: "300px" }} // Limiting the maximum width of the search box
                    />
                  </div>
                    <div class="table-responsive">
                      <table class="table table-first text-nowrap" id="table">
                        <thead>
                          <tr>
                            <th>Sr no.</th>
                            <th>User</th>
                            <th>Notary</th>
                            <th>Notary Charge</th>
                            <th>Travel Fee</th>
                            <th>Notary Earning</th>
                            <th>Admin Earning</th>
                            <th>Payment Status</th>
                            {/* <th>Action</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {paginated?.length !== 0 &&
                            paginated.map((transaction, i) => (
                              <tr>
                                <td>{(page + 1) * itemsPerPage -
                                  itemsPerPage +
                                  i +
                                  1}</td>
                                  {console.log("transaction--->",transaction)}
                                <td>{transaction?.sender?.name}</td>
                                <td>{transaction?.notary?.name}</td>
                                <td>
                                  ${" "}
                                  {transaction?.notary_charge == null
                                    ? "NIL"
                                    : Number(
                                        transaction?.notary_charge
                                      ).toFixed(2)}
                                </td>
                                <td>
                                  $ {Number(transaction?.travel_fee).toFixed(2)}
                                </td>
                                <td>
                                  $ {transaction?.notary_earning == null?"NIL":Number(transaction?.notary_earning).toFixed(2)}
                                </td>
                                <td>
                                  $ {transaction?.admin_earning == null?"NIL":Number(transaction?.admin_earning).toFixed(2)}
                                </td>
                                <td>
                                  {" "}
                                  <span class="badge bg-success">
                                    {transaction?.status}
                                  </span>
                                </td>
                                {/* <td>
                                                            <a href="#" class="me-2" title="view">
                                                                <button class="btn btn-light" type="button"><RiEyeFill /></button>
                                                            </a>
                                                            <a href="#modal-restriction" class="me-2" title="restricted" data-bs-toggle="modal" >
                                                                <button class="btn btn-light" type="button"><RiForbidFill /></button>
                                                            </a>
                                                            <a href="#modal-delete" data-bs-toggle="modal" title="delete">
                                                                <button class="btn btn-light" type="button"><RiDeleteBin3Fill />
                                                                </button>
                                                            </a>
                                                        </td> */}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {transactions.length > 10 && (
          <ReactPaginate
            breakLabel="..."
            breakClassName={"break"}
            nextLabel="next >"
            containerClassName={"pagination"}
            activeClassName={"active"}
            onPageChange={handlePageClick}
            forcePage={page}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< previous"
          />
        )}
      </main>
    </>
  );
};

export default JobTransactions;
