import axios from "axios";

 //const app_uri = "http://localhost:5003/admin";
  const app_uri = 'https://mobilenotary-api.alcax.com/admin'
 //const app_uri = 'https://api.mobilenotaryx.ai/admin'
// axios.defaults.withCredentials=true

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const sessionId = localStorage.getItem("session");

export const adminLogin = (payload) => {
  return axios.post(app_uri + "/admin-login", payload, {
    withCredentials: false,
  });
};

export const addJobService = (payload) => {
  return axios.post(app_uri + "/add-service", payload);
};

export const getJobServices = () => {
  return axios.get(app_uri + "/services");
};

export const getServiceById = (id) => {
  return axios.get(app_uri + `/get-service/${id}`);
};

export const getUserList = () => {
  return axios.get(app_uri + `/get-users-notary`);
};
export const getNotaryList = () => {
  return axios.get(app_uri + `/get-users-notary?type=notary`);
};
export const getSupport = () => {
  return axios.get(app_uri + `/supports`);
};
export const markAsResolvedSupport = (id) => {
  return axios.put(app_uri + `/help-support-resolved/${id}`);
};
export const deletedHelpSupport = (id) => {
  return axios.put(app_uri + `/delete-help-support/${id}`);
};
export const helpAndSupportDetail = (id) => {
  return axios.get(app_uri + `/get-detail-help-support/${id}`);
};

export const changeUserStatus = async (id, status) => {
  return await axios.put(app_uri + `/user/${status}/${id}`);
};

export const editJobService = (id, data) => {
  return axios.patch(app_uri + `/edit-service/${id}`, data);
};

export const adminLogout = () => {
  return axios.get(app_uri + `/admin-logout`);
};

export const addFlatPrice = (payload) => {
  return axios.post(app_uri + "/add-price", payload);
};

export const getFlatPrices = () => {
  return axios.get(
    app_uri + `/prices`
    // {
    //     headers: {
    //         'Authorization': `Bearer ${sessionId}`,
    //     },
    // }
  );
};

export const getFlatPriceById = (id) => {
  return axios.get(app_uri + `/get-price-details/${id}`);
};

export const editFlatPrice = (id, payload) => {
  return axios.patch(app_uri + `/edit-price/${id}`, payload);
};

export const getStates = () => {
  return axios.get(app_uri + `/states`);
};

export const getCities = () => {
  return axios.get(app_uri + `/cities`);
};
export const getLanguages = () => {
  return axios.get(app_uri + `/get-languages`);
};
export const addBooking = (payload) => {
  return axios.post(app_uri + "/add-booking", payload);
};
export const createJob = (payload) => {
  return axios.post(app_uri + "/create-job", payload);
};
export const cancelJob = (payload) => {
  return axios.post(app_uri + "/job-cancel", payload);
};

export const getJobTransactions = () => {
  return axios.get(app_uri + `/job-transactions`);
};

export const getJobs = () => {
  return axios.get(app_uri + "/get-jobs");
};

export const getJobDetails = (id) => {
  return axios.get(app_uri + `/get-job-details/${id}`);
};

export const getUserDetails = (id) => {
  return axios.get(app_uri + `/get-user-details/${id}`);
};
export const updateNotary = (notaryId,payload) => {
  return axios.put(app_uri + `/update-notary/${notaryId}`,payload);
};

export const makePayment = (payload) => {
  return axios.post(app_uri + "/make-payment", payload);
};
export const notaryApproved = (payload) => {
  return axios.post(app_uri + "/natory-signup-request-approve", payload);
};
export const businessUserApproved = (payload) => {
  return axios.post(app_uri + "/natory-signup-request-approve", payload);
};
export const deleteBusinessUser = (id) => {
  return axios.delete(app_uri + `/delete-business-user/${id}`);
};


export const jobMarkedAsCompleteChange = (payload) => {
  return axios.put(app_uri + "/job-success-unsuccess", payload);
};

export const faqList = () => {
  return axios.get(app_uri + "/get-faqs");
};

export const addFaq = (payload) => {
  return axios.post(app_uri + "/add-faq", payload);
};

export const getFaqById = (id) => {
  return axios.get(app_uri + `/faq/${id}`);
};

export const editFaq = (id, payload) => {
  return axios.put(app_uri + `/faq/${id}`, payload);
};

export const deleteFaq = (id) => {
  return axios.delete(app_uri + `/faq/${id}`);
};

export const addStaticContent = (payload) => {
  return axios.post(app_uri + "/add-content", payload);
};
export const editStaticContent = (payload) => {
  return axios.put(app_uri + "/update-content", payload);
};

export const getStaticContent = (type) => {
  return axios.get(app_uri + `/get-content?type=${type}`);
};

export const getFlatPriceByStateName = (stateName) => {
  return axios.get(app_uri + `/pricesByStateName?stateName=${stateName}`);
};

export const retrieveNotaryTransactions = () => {
  return axios.get(app_uri + "/transactions");
};

export const ApproveTrans = (transactionId) => {
  return axios.put(app_uri + `/transaction/Approved/${transactionId}`);
};

export const RejectTrans = (transactionId) => {
  return axios.put(app_uri + `/transaction/Rejected/${transactionId}`);
};
export const getContactData = () => {
  return axios.get(app_uri + "/contactForms");
};
