import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { IoMdArrowRoundBack } from "react-icons/io";
import {
  addFlatPrice,
  addJobService,
  editFlatPrice,
  getCities,
  getFlatPriceById,
  getStates,
} from "services/apiServices";
import Autocomplete from "react-google-autocomplete";
import {Link, json, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

const EditFlatPrice = () => {
  const navigate = useNavigate();
  // const [states, setStates] = useState([])
  const [selectedState, setSelectedState] = useState("");
  const [selectedStatePrice, setSelectedStatePrice] = useState("");
  const [cities, setCities] = useState({});
  const [showCity, setShowCity] = useState([]);
  const [cityPrices, setCityPrices] = useState([]);
  const [myCityPrices, setMyCityPrices] = useState([]);
  // const [flatCityPrices, setFlatCityPrices] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  let { id } = useParams();
  const formSubmit = (data) => {
    const updatedMyCityArray = showCity
    .map((item) => {
      const myCityData = myCityPrices.find((city) => city.name === item.name);

      if (myCityData && item.price) {
        return {
          name: myCityData.name,
          price: item.price,
          _id: myCityData._id,
        };
      } else if (item.price) {
        return { name: item.name, price: item.price };
      }

      return null;
    })
    .filter(Boolean);

  data.cities = updatedMyCityArray;
    editFlatPrice(id, data)
      .then((response) => {
        if (response.status == 201) {
          toast.success(response.data.message);
          navigate("/prices");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const cityPriceChange = (e, i, stateName) => {
    const newCityPrices = [...showCity];
    newCityPrices[i] = {
      name: stateName,
      price: e.target.value,
    };
    setShowCity(newCityPrices);
  };
  const adjustPrice = () => {
   
    console.log("cityprices",cityPrices)
    console.log("cities",cities)

    const updatedShowCity = cities[selectedState]?.map((cityName) => {
      const myCityData = cityPrices.find((city) => city?.name === cityName);

      return {
        name: cityName,
        price: myCityData ? myCityData?.price : "",

      };
    });

    setShowCity(updatedShowCity);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const citiesResponse = await getCities();
        if (citiesResponse.status === 200) {
          setCities(citiesResponse.data.data);
        }
  
        const flatPriceResponse = await getFlatPriceById(id);
        if (flatPriceResponse.status === 200) {
          const flatPriceData = flatPriceResponse.data.data;
          setSelectedState(flatPriceData.state.name);
          setSelectedStatePrice(flatPriceData.price);
          setMyCityPrices(flatPriceData.cities);
          setCityPrices(flatPriceData.cities);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, [id]);
  
  useEffect(() => {
    adjustPrice();
  }, [selectedState, cityPrices]);
  


  return (
    <>
      <main class="content">
        <div>
          <a id="btn-toggle" href="#" class="sidebar-toggler break-point-sm">
            <i class="ri-menu-line ri-xl"></i>
          </a>
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
              <div class="clearfix mt-3 mb-3">
                  <div class="float-start">
                    <Link to={"/prices"}>
                    <h6 class="text-contact fw-bolder"><IoMdArrowRoundBack />Back</h6>
                    </Link>
                  </div>
                </div>
                <div class="clearfix mt-3 mb-4">
                  <div class="float-start">
                    <h2 class="text-contact fw-bolder">Edit Flat Price</h2>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="card border-0 manage-usr-text mb-5">
                  <div class="card-body">
                    <form class="p-4" onSubmit={handleSubmit(formSubmit)}>
                      <div className="row">
                        <div class="col-md-6">
                          <div class="mb-4">
                            <label for="address" class="form-label ">
                              State
                            </label>
                            <input
                              type="text"
                              class="py-2 form-control"
                              value={selectedState}
                              disabled
                            />
                            {/* <select className='form-select'  {...register('state', { required: "Please select the state" })} onChange={(e)=>setSelectedState(states[e.target.selectedIndex-1]?.name)}>
                                                        <option value=''>Select State</option>
                                                        {states && states.map((state) =>
                                                            <option key={state.code} value={state.code} >{state.name} </option>
                                                        )}
                                                    </select> */}
                          </div>
                          {errors.name && (
                            <span className="error">{errors.name.message}</span>
                          )}
                        </div>
                        {/* <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="" class="fw-bolder text-black mb-2">Flat Price</label>
                                                    <input type="text" class="form-control py-2" value={selectedStatePrice} disabled/>
                                                </div>
                                                {errors.price && <span className='error'>{errors.price.message}</span>}
                                            </div> */}

                        <div className="col-md-12 mt-2">
                          <div class="flat-table table-responsive">
                            <table
                              class="table table-first text-nowrap"
                              id="table"
                            >
                              <thead>
                                <tr>
                                  {/* <th>Sr no.</th> */}
                                  <th>City Name</th>
                                  <th>Price</th>
                                  {/* <th>Phone Number</th>
                                          <th>Account Status</th>*/}
                                  {/* <th>Action</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {showCity?.length !== 0 &&
                                  showCity?.map((item, index) => (
                                    <tr key={item.name}>
                                      {/* <td>{i + 1}</td> */}
                                      <td>{item.name}</td>
                                      <td className="d-flex align-items-center">
                                        $
                                        <input
                                          type="number"
                                          class="form-control py-2 ms-2"
                                          value={item?.price}
                                          onChange={(e) =>
                                            cityPriceChange(
                                              e,
                                              index,
                                              item?.name
                                            )
                                          }
                                        />
                                      </td>

                                      {/* <td>
                                                            <FiEdit onClick={()=>navigate(`/edit-service/${service._id}`)}/>
                                                        </td> */}
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <button
                            type="submit"
                            class="btn btn-linear px-5 mt-4"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default EditFlatPrice;
