import { useEffect, useState } from "react";
import { RiDeleteBin3Fill, RiEyeFill, RiForbidFill, RiPencilLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { getJobTransactions, getJobs, makePayment, cancelJob } from "services/apiServices";
import { MdCancel } from "react-icons/md";
import ReactPaginate from 'react-paginate';
import { Button, Modal } from "react-bootstrap";
import { FaMoneyBill } from "react-icons/fa";
import { toast } from "react-toastify";
import EditJob from "./EditJob";
import CancelledJob from "./CancelledJob";

const AllJobs = () => {
    const [jobs, setJobs] = useState([])
    const [paginated, setPaginated] = useState([])
    const [page, setPage] = useState(0)
    const [offset, setOffset] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [pageCount, setPageCount] = useState(0)
    const [search, setSearch] = useState('')
    const [filter, setFilter] = useState('all')
    const [show, setShow] = useState(false);
    const [charge, setCharge] = useState('')
    const [jobDetail, setJobDetail] = useState(null);
    const [showSuccess, setShowSuccess] = useState(false);
    const [jobId, setJobId] = useState('')
    const [cancelledJob, setCancelledJob] = useState(false)



    const handleClose = () => {
        setShow(false)
        setCancelledJob(false)
    };
    const handleShow = (id) => {

        setJobDetail(jobs.filter((item) => item._id == id)[0])
        setShow(true)
    };
    const handleCloseSuccess = () => {
        setShowSuccess(false);
    };
    const handleSearch = (e) => {
        setSearch(e.target.value)
        setOffset(0)
        setPage(0)

    }
    const handleJobFilter = (e) => {
        setFilter(e.target.value)
        setOffset(0)
        setPage(0)
    }
    const paginationHandler = () => {
        const endOffset = offset + itemsPerPage;
        let paginated = []
        if (search.length > 0) {
            let searchArr = filter == "all" ? jobs.filter((item) => item.posted_by?.name.toLowerCase().includes(search.toLowerCase())) : jobs.filter((item) => (item.posted_by?.name.toLowerCase().includes(search.toLowerCase()) && item.marked_as_completed == filter))
            setPageCount(Math.ceil(searchArr.length / itemsPerPage))
            paginated = searchArr.slice(offset, endOffset)
        }
        else {
            setPageCount(Math.ceil(jobs.length / itemsPerPage))
            let filtered = filter == "all" ? jobs : jobs.filter((item) => item.marked_as_completed == filter)
            paginated = filtered.slice(offset, endOffset)
        }
        setPaginated(paginated)
    }
    const handlePageClick = (e) => {
        setPage(e.selected)
        const newOffset = (e.selected * itemsPerPage);
        setOffset(newOffset)
    }
    const initiatePayment = () => {
        if (jobDetail.marked_as_completed == 1 && charge == '') {
            return toast.error('Please enter amount.')
        }
        const payload = {
            "jobId": jobDetail._id,
            "notary_charge": charge
        }
        makePayment(payload).then(response => {
            if (response.status == 200) {
                handleClose()
                getAll()
                toast.success(response.data.message)
            }
        }).catch(err => {
            toast.error(err.response.data.message)
        })
    }

    useEffect(() => {
        paginationHandler()
    }, [jobs, offset, search, filter]);

    const getAll = () => {
        getJobs().then(response => {
            if (response.status == 200) {
                setJobs(response.data.data)
                setPageCount(Math.ceil(response.data.data.length / itemsPerPage))
            }
        })
    }


    const cancelJobPass = () => {
        const payload = {
            "job": jobId
        }
        cancelJob(payload).then(response => {
            getAll()
            if (response.status == 200) {
                toast.success(response.data.message)
            }
        }).catch(err => {
            toast.error(err.response.data.message)
        })

    }

    const editHandler = (jobId) => {
        setShowSuccess(true)
        setJobId(jobId)

    }
    const newDataHandler = (newData) => {
        if (newData == 200) {
            getAll()
        }
    }
    const handleCancelled = (id) => {
        setJobId(id)
        setCancelledJob(true)
    }
    useEffect(() => {
        getAll()
    }, [])

    return (
        <>
            <main class="content">
                <div>
                    <a id="btn-toggle" href="#" class="sidebar-toggler break-point-sm">
                        <i class="ri-menu-line ri-xl"></i>
                    </a>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="clearfix mt-3 mb-4">
                                    <div class="float-start">
                                        <h2 class="text-contact fw-bolder">Manage Jobs</h2>
                                    </div>
                                    <div class="float-end">
                                        <nav aria-label="breadcrumb">
                                            <ol class="breadcrumb contact-bread">
                                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                                <li class="breadcrumb-item active" aria-current="page">Manage Jobs</li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {cancelledJob && (<CancelledJob cancelledJob={cancelledJob} handleClose={handleClose} cancelJobPass={cancelJobPass} />)}
                        {showSuccess && (<EditJob showSuccess={showSuccess}
                            handleClose={handleCloseSuccess} jobId={jobId} onDataPass={newDataHandler} />)}
                        <div class="row">
                            <div class="col-12">
                                <div class="card border-0 manage-usr-text mb-5">
                                    <div class="card-body">
                                        <div className="clearfix mb-3">
                                            <div className="float-start">
                                                <div class="search-user"><label className="d-flex align-items-center">Search  <input type="search" class="search-input form-control ms-2" placeholder="" aria-controls="table" onChange={handleSearch} /></label></div>
                                            </div>
                                            <div className="float-end">
                                                <div class="search-user"><label className="d-flex align-items-center text-nowrap">Filter Job
                                                    {/* <input type="search" class="search-input" placeholder="" aria-controls="table" onChange={handleSearch} /> */}
                                                    <select onChange={handleJobFilter} className="ms-2 form-control form-select">
                                                        <option value="all">All</option>
                                                        <option value="0">Unsuccessfull</option>
                                                        <option value="1">Successfull</option>

                                                    </select>
                                                </label></div>
                                            </div>
                                        </div>
                                        <div class="table-responsive">
                                            <table class="table table-first text-nowrap" id="table">
                                                <thead>
                                                    <tr>
                                                        <th>Sr no.</th>
                                                        <th>User</th>
                                                        <th>Notary</th>
                                                        <th>Job Type</th>
                                                        <th>Service Name</th>
                                                        <th>Marked as complete</th>
                                                        <th>Job Status</th>

                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {paginated?.length !== 0 && paginated.map((job, index) =>
                                                        <tr>
                                                            <td>{((page + 1) * itemsPerPage - itemsPerPage) + index + 1}</td>
                                                            <td>{job.posted_by?.name}</td>
                                                            <td>{job.assigned_to ? job.assigned_to.name : "Not assigned"}</td>
                                                            <td><strong>{job.job_type}</strong></td>
                                                            <td>{job.service?.service_name}</td>
                                                            <td>{job.marked_as_completed == 0 ? "Unsuccessful" : job.marked_as_completed == 1 ? "Successful" : (job.status == "auto_cancelled" || job.status == "cancelled")?"cancelled":"pending"}</td>
                                                            <td className={`${job.status == "cancelled" ? "text-danger" : job.status == "auto_cancelled" ? "text-muted" : job.status == "accepted" ? "text-success" : job.status == "pending" ? "text-warning" : ''}`}>{job.status}</td>
                                                            {/* <td>$ {Number(transaction.travel_fee).toFixed(2)}</td>
                                                        <td> <span class="badge bg-success">{transaction.status}</span></td> */}
                                                            <td>
                                                                <Link to={`/job-details/${job._id}`} class="me-2" title="view">
                                                                    <button class="btn btn-light" type="button"><RiEyeFill /></button>
                                                                </Link>
                                                                <button class="btn btn-light me-2" type="button" onClick={() => handleCancelled(job._id)}><MdCancel /> Cancelled
                                                                </button>
                                                                {(job.marked_as_completed == 0 || job.marked_as_completed == 1) && <button class="btn btn-light me-2" type="button" onClick={() => editHandler(job._id)}><RiPencilLine /></button>}

                                                                {((job.marked_as_completed == 0 || job.marked_as_completed == 1) && job.payment_status == false) &&
                                                                    <Link data-bs-toggle="modal" title="payment" >
                                                                        <button class="btn btn-light " type="button" onClick={() => handleShow(job._id)}><FaMoneyBill />
                                                                        </button>
                                                                    </Link>
                                                                }

                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                                {paginated.length == 0 && <span className="no-data"><p>No Data Found</p></span>}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {jobs.length > 10 &&
                    <ReactPaginate
                        breakLabel="..."
                        breakClassName={'break'}
                        nextLabel="next >"
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        onPageChange={handlePageClick}
                        forcePage={page}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="< previous"

                    />
                }
            </main>

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Job details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div class="mb-4">
                                    <h6 class="fw-bolder">Note</h6>
                                    <h6 class="fw-bolder text-black">{jobDetail?.note}</h6>
                                </div>
                            </div>
                            {jobDetail?.service.service_name == "Standard Notarization" ?
                                <div className="col-md-12">
                                    <div class="mb-4">
                                        <h6 class="fw-bolder">No. of signatures</h6>
                                        <h6 class="fw-bolder text-black">{jobDetail?.no_of_signs}</h6>
                                    </div>
                                </div>
                                :
                                <div className="col-md-12">
                                    <div class="mb-4">
                                        <h6 class="fw-bolder">Type</h6>
                                        <h6 class="fw-bolder text-black">{jobDetail?.type}</h6>
                                    </div>
                                </div>
                            }
                            {jobDetail?.marked_as_completed == 1 &&
                                <div className="col-md-12">
                                    <div class="mb-4">
                                        <h6 class="fw-bolder">Notary Charge</h6>
                                        <input type="text" class="search-input form-control" placeholder="" aria-controls="table" onChange={(e) => setCharge(e.target.value)} />
                                    </div>
                                </div>
                            }

                            <div className="col-md-12" onClick={initiatePayment}>
                                <button className="btn btn-light">Done</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    );
}

export default AllJobs;