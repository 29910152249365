import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { modalSubmit } from "./functions";
import { toast } from "react-toastify";

const SimpleModal = ({ show, setShow, type, data, cb }) => {
  const handleClose = () => {
    setShow(false);
  };
  const handleSubmit = () => {
    modalSubmit(type, data).then((response) => {
     
      if (response.data.success) {
        toast.success(response.data.message);
        cb();
      }
      handleClose();
    });
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
    <Modal.Header closeButton={false} className="border-0 mt-4 pt-0 pb-2">
    {/* <div className="d-flex justify-content-center w-100">
                    <Modal.Title className="h5"> Deactivate Notary</Modal.Title>
                </div> */}
    </Modal.Header>
    <Modal.Body className="text-center px-md-5 pt-0">
      <h4 className="text-red-sure">
      <p>Are you sure you want to {data.status=="deactivate"?"Deactivate":"Activate"} this account?</p>
      </h4>
    </Modal.Body>
    <Modal.Footer className="border-0 d-flex justify-content-around pt-0   w-75 mx-auto mb-4 pb-0">
      <button
        type="submit"
        onClick={handleSubmit}
        className="btn btn-invite new next fw-bolder update-class "
      >
        Yes
      </button>
      <Button
        variant="secondary"
        onClick={handleClose}
        className="btn btn-closed new "
      >
        No
      </Button>
    </Modal.Footer>
  </Modal>
  );
};

export default SimpleModal;
