import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from 'react-bootstrap/Form';
import { toast } from "react-toastify";
import { getUserDetails, updateNotary } from "services/apiServices";
import LoadingAction from "components/common/LoadingAction";


const buttonWidth = {
    width: "100%",
};

const EditNotary = (props) => {
    const { showSuccess, handleClose, notaryId,onDataPass } = props;
    const [details, setDetails] = useState('')
    const [showLoader, setShowLoader] = useState(false);
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [commissionDoc, setCommissionDoc] = useState('')
    const [omissionInsurance, setOmissionInsurance] = useState('')
    const [govtIdProof, setGovtIdProof] = useState('')

    useEffect(() => {
        const getNotaryInfo = () => {
            getUserDetails(notaryId).then(res => {
                if (res.status == 200) {
                    setDetails(res.data.data)
                    setName(res.data.data.name)
                    setEmail(res.data.data.email)
                }
            })
        }
        getNotaryInfo()
    }, [])

    const handleNameChange = (e) => {
        setName(e.target.value)
    };
    const handleEmailChange = (e) => {
        setEmail(e.target.value)
    };
    const handleCommissionDocChange = (e) => {
        setCommissionDoc(e.target.files[0])
    };
    const handleOmissionInsuranceChange = (e) => {
        setOmissionInsurance(e.target.files[0])
    };
    const handleGovtIdProofChange = (e) => {
        setGovtIdProof(e.target.files[0])
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("commissionDoc", commissionDoc);
        formData.append("omissionInsurance", omissionInsurance);
        formData.append("govtIdProof", govtIdProof);
        setShowLoader(true)
        updateNotary(notaryId, formData).then(response => {
            onDataPass(response.status)
            if (response.status == 201) {
                setShowLoader(false)
                handleClose()
                toast.success(response.data.message)
            }
        }).catch(err => {
            setShowLoader(false)
            toast.error(err.response.data.message)
        })

    };

    return (
        <Modal show={showSuccess} onHide={handleClose} centered size="md">
            <Modal.Header closeButton={true} className="border-0 mt-4 pt-0 pb-2">
                <div className="d-flex justify-content-center w-100">
                    <Modal.Title className="h5">Edit Notary</Modal.Title>
                </div>
            </Modal.Header>
            {showLoader && <LoadingAction />}
            <Modal.Body className="p-4">
                <form onSubmit={handleSubmit} className="form-login n">
                    <div className="mb-4">
                        <label className="form-label">Name</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Category Name"
                            value={name}
                            onChange={handleNameChange}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="form-label">Email</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Category Name"
                            value={email}
                            onChange={handleEmailChange}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="form-label">Notary Commission Document</label>
                        <input
                            type="file"
                            className="form-control"
                            onChange={handleCommissionDocChange}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="form-label">Omission Insurance</label>
                        <input
                            type="file"
                            className="form-control"
                            onChange={handleOmissionInsuranceChange}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="form-label">Government ID Proof</label>
                        <input
                            type="file"
                            className="form-control"
                            onChange={handleGovtIdProofChange}
                        />
                    </div>

                    <div className="text-center ">
                        <button
                            type="submit"
                            className="btn btn-primary py-2 fw-bolder update-class"
                            style={buttonWidth}
                        >
                            Update
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default EditNotary