import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {  getServiceById } from "services/apiServices";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";

const FlatPricesList = () => {
    const [servicePrices, setServicePrices] = useState([])
    let navigate = useNavigate()
    let { id } = useParams();

    useEffect(() => {
    
        const getPrices = () => {
            getServiceById(id).then(response => {
                if (response.status == 200) {
                    console.log("check states---",response?.data?.data?.states)
                    setServicePrices(response?.data?.data?.states)
                }
            }).catch(err => {
                toast.error(err.response.data.message)
            })
        }
        getPrices()
    }, [])
    return (
        <>
            <main class="content">

                <a id="btn-toggle" href="#" class="sidebar-toggler break-point-sm">
                    <i class="ri-menu-line ri-xl"></i>
                </a>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="clearfix mt-3 mb-4">
                                <div class="float-start">
                                    <h2 class="text-contact fw-bolder">Manage Flat Prices</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        
                        <div class="col-12">
                            <div class="card border-0 manage-usr-text mb-5">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table table-first text-nowrap" id="table">
                                            <thead>
                                                <tr>
                                                    <th>Sr no.</th>
                                                    <th>State</th>
                                                    <th>Price</th>
                                                  
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {servicePrices?.length !== 0 && servicePrices?.map((elem, i) =>

                                                    <tr>
                                                        <td>{i + 1}</td>
                                                        <td>{elem?.state}, {elem?.code}</td>
                                                        <td>${elem?.price}</td>

                                                    </tr>
                                                )}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default FlatPricesList;