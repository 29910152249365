import { useEffect, useState } from "react";
import { RiDeleteBin3Fill, RiEyeFill, RiForbidFill } from "react-icons/ri";
import { FaCheck } from 'react-icons/fa';
import { MdCancel } from "react-icons/md";
import { getUserList, businessUserApproved, deleteBusinessUser } from "services/apiServices";
import ReactPaginate from 'react-paginate';
import { toast } from "react-toastify";
import DeleteBusinessUser from "./DeleteBusinessUser";
import ApprovedBusinessUser from "./ApprovedBusinessUser";

const ManageBusiness = () => {
    const [users, setUsers] = useState([])
    const [paginated, setPaginated] = useState([])
    const [page, setPage] = useState(0)
    const [offset, setOffset] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(12)
    const [pageCount, setPageCount] = useState(0)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [businessUserId, setBusinessUserId] = useState('')
    const [search, setSearch] = useState('')
    const [showApproved, setShowApprovedl] = useState(false)
    const [businessData, setBusinessData] = useState()



    const getUsers = () => {
        getUserList().then(response => {
            if (response.status == 200) {
                let data = response.data.data.filter((item) => item.serviceType == "business")
                setUsers(data)
                setPageCount(Math.ceil(data.length / itemsPerPage))
            }
        })
    }



    const handlePageClick = (e) => {
        setPage(e.selected)
        const newOffset = (e.selected * itemsPerPage);
        setOffset(newOffset)
    }

    const paginationHandler = () => {
        const endOffset = offset + itemsPerPage;
        let paginated = []
        if (search.length > 0) {
            let searchArr = users.filter((item) => item?.name?.toLowerCase().includes(search.toLowerCase()))
            setPageCount(Math.ceil(searchArr.length / itemsPerPage))
            paginated = searchArr.slice(offset, endOffset)
        }
        else {
            setPageCount(Math.ceil(users.length / itemsPerPage))
            paginated = users.slice(offset, endOffset)
        }
        setPaginated(paginated)
    }

    const handleApproved = (user, is_approved) => {
        const payload = {
            "email": user,
            is_approved: is_approved
        }
        setShowApprovedl(true)
        setBusinessData(payload)
    }
    const deleteHandler = (id) => {
        setBusinessUserId(id)
        setShowDeleteModal(true)
    }
    const handleSearch = (e) => {
        setSearch(e.target.value)
        setOffset(0)
        setPage(0)

    }
    const handleClose = () => {
        setShowDeleteModal(false)
        setShowApprovedl(false)

    }
    const handleDeletePass = () => {
        deleteBusinessUser(businessUserId).then(response => {
            if (response.status == 200) {
                toast.success(response.data.message)
                getUsers()
            }
        }).catch(err => {
            toast.error(err.response.data.message)
        })
    }
    const approvedBusinessAccount = () => {
        const payload = {
            "email": businessData.email,
        }
        businessUserApproved(payload).then(response => {
            if (response.status == 200) {
                toast.success(response.data.message)
                getUsers()
            }
        }).catch(err => {
            toast.error(err.response.data.message)
        })
    }

    useEffect(() => {
        paginationHandler()
    }, [users, offset, search]);

    useEffect(() => {
        getUsers()
    }, [])

    return (
        <>
            <main class="content">
                <div>
                    <a id="btn-toggle" href="#" class="sidebar-toggler break-point-sm">
                        <i class="ri-menu-line ri-xl"></i>
                    </a>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="clearfix mt-3 mb-4">
                                    <div class="float-start">
                                        <h2 class="text-contact fw-bolder">Manage Business</h2>
                                    </div>
                                    <div class="float-end">
                                        <nav aria-label="breadcrumb">
                                            <ol class="breadcrumb contact-bread">
                                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                                <li class="breadcrumb-item active" aria-current="page">Manage Business</li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showApproved && (<ApprovedBusinessUser showApproved={showApproved} handleClose={handleClose} approvedBusinessAccount={approvedBusinessAccount} businessData={businessData} />)}
                        <DeleteBusinessUser showDeleteModal={showDeleteModal} handleClose={handleClose} handleDeletePass={handleDeletePass} />
                        <div class="row">
                            <div class="col-12">
                                <div class="card border-0 manage-usr-text mb-5">
                                    <div class="card-body">
                                        <div class="search-user"><label>Search:<input type="search" class="search-input" placeholder="" aria-controls="table" onChange={handleSearch} /></label></div>
                                        <div class="table-responsive">
                                            <table class="table table-first text-nowrap" id="table">
                                                <thead>
                                                    <tr>
                                                        <th>Sr no.</th>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Phone Number</th>
                                                        <th>User Type</th>
                                                        <th>Account Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {paginated?.length !== 0 && paginated.map((user, i) =>
                                                        <tr>
                                                            <td>{((page + 1) * itemsPerPage - itemsPerPage) + i + 1}</td>
                                                            <td>{user.name}</td>
                                                            <td>{user.email}</td>
                                                            <td>{user.phoneNumber}</td>
                                                            <td>{user.serviceType}</td>
                                                            <td> <span class={user.is_approved ? "badge bg-success" : "badge bg-secondary"}>{user.is_approved ? "Approved" : "Pending"}</span></td>
                                                            <td>

                                                                <button class="btn btn-light" type="button" onClick={() => deleteHandler(user._id)}><RiDeleteBin3Fill />
                                                                </button>
                                                                {user.is_approved ? <button className="btn btn-dark  align-items-center ms-2" type="button" onClick={() => handleApproved(user.email, user.is_approved)} > <span className='ms-1'><MdCancel /> Unapprove</span></button> :
                                                                    <button className="btn btn-light  align-items-center ms-2" type="button" onClick={() => handleApproved(user.email, user.is_approved)} > <span className='ms-1'><FaCheck /> Approve</span></button>}

                                                            </td>
                                                        </tr>

                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {users.length > 10 &&
                    <ReactPaginate
                        breakLabel="..."
                        breakClassName={'break'}
                        nextLabel="next >"
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        onPageChange={handlePageClick}
                        forcePage={page}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="< previous"

                    />
                }
            </main>
        </>
    );
}


export default ManageBusiness