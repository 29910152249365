import AppContext from "context/appContext";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IoMdArrowRoundBack } from "react-icons/io";
import {
  editJobService,
  getServiceById,
  getStates,
} from "services/apiServices";

const ManageService = () => {
  const context = useContext(AppContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
  } = useForm();
  const [avatar, setAvatar] = useState("");
  const [service, setService] = useState({});
  const [showState, setShowState] = useState([]);
  const [serivceStates, setServiceStates] = useState([]);
  const [states, setStates] = useState([]);
  // const [image, setImage] = useState('')
  let navigate = useNavigate();
  let { id } = useParams();

  const formSubmit = (data) => {
    let states = showState
      ?.map((item) => {
        if (!item.price) {
          return null;
        } else {
          return item;
        }
      })
      .filter(Boolean);

    let obj = { states: states };
    console.log("obj", obj);
    editJobService(id, obj)
      .then((response) => {
        if (response.status == 200) {
          navigate("/servicePrices");
          toast.success(response.data.message);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
  const cityPriceChange = (e, i) => {
    const { value } = e.target;
    if (value < 0) return;
    const mydata = [...showState];
    mydata[i].price = value;
    setShowState(mydata);
  };

  // const imageObj = (e) => {
  //     console.log(e.target.files)
  //     setImage(e.target.files[0])
  //     let file_url = URL.createObjectURL(e.target.files[0])
  //     // console.log(file_url);
  //     setAvatar(file_url)
  // }

  const mapTheData = () => {
    let myData = states?.map((state) => {
      let exist = service?.states?.find((item) => item?.state === state?.name);
      if (exist) {
        return {
          state: exist?.state,
          price: exist?.price,
          code: exist?.code,
          _id: exist?._id,
        };
      } else {
        return {
          state: state?.name,
          price: "",
          code: state.code,
        };
      }
    });
    setShowState(myData);
  };
  const GetServiceDetail = () => {
    getServiceById(id)
      .then((response) => {
        if (response?.status == 200) {
          setService(response?.data?.data);
          setAvatar(context.img_uri + response?.data?.data?.img);
          setServiceStates(response?.data?.data?.states);
          // setValue('price', response.data.data.price)
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
  const getStatesList = () => {
    getStates().then((res) => {
      if (res.status == 200) {
        setStates(res.data.list);
      }
    });
  };

  useEffect(() => {
    getStatesList();
    GetServiceDetail();
  }, [id]);

  useEffect(() => {
    mapTheData();
  }, [states, service]);

  return (
    <>
      <main class="content">
        <div>
          <a id="btn-toggle" href="#" class="sidebar-toggler break-point-sm">
            <i class="ri-menu-line ri-xl"></i>
          </a>
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="clearfix mt-3">
                  <div class="float-start">
                    <Link to={"/servicePrices"}>
                      <h5 class="text-contact fw-bolder">
                        <IoMdArrowRoundBack /> Back
                      </h5>
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="clearfix mt-3 mb-4">
                  <div class="float-start">
                    <h2 class="text-contact fw-bolder">
                      Manage {service?.service_name}
                    </h2>
                  </div>
                </div>
              </div>

            </div>

            <div class="row">
              <div class="col-12">
                <div class="card border-0 manage-usr-text mb-5">
                  <div class="card-body">
                    <form class="p-4" onSubmit={handleSubmit(formSubmit)}>
                      <div className="row">
                        <div className="col-md-12">
                          <div class=" flat-table table-responsive">
                            <table
                              class="table table-first text-nowrap"
                              id="myTable"
                            >
                              <thead>
                                <tr>
                                  <th>State Name</th>
                                  <th>Price</th>
                                </tr>
                              </thead>
                              <tbody>
                                {showState?.length !== 0 &&
                                  showState?.map((item, index) => (
                                    <tr key={item?.state}>
                                      <td>{item.state}</td>
                                      <td className="d-flex align-items-center">
                                        ${" "}
                                        <input
                                          type="number"
                                          class="form-control py-2 ms-2"
                                          value={item?.price}
                                          onChange={(e) =>
                                            cityPriceChange(e, index)
                                          }
                                        />
                                      </td>

                                      {/* <td>
                                                              <FiEdit onClick={()=>navigate(`/edit-service/${service._id}`)}/>
                                                          </td> */}
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <button
                            type="submit"
                            class="btn btn-linear px-5 mt-4"
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ManageService;
