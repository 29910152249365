import { useEffect, useState } from "react";
import { RiDeleteBin3Fill, RiEyeFill, RiForbidFill, RiPencilFill } from "react-icons/ri";
import { IoClose } from "react-icons/io5";
import { FaCheck } from 'react-icons/fa';
import { Link } from "react-router-dom";
import { ImUserMinus } from "react-icons/im";
import { toast } from "react-toastify";
import ReactPaginate from 'react-paginate';

import { getNotaryList, notaryApproved } from "services/apiServices";
import SimpleModal from "components/common/SimpleModal";
import EditNotary from "./EditNotary";
import ApprovedNotary from "./ApprovedNotary";

const Notaries = () => {
  const [show, setShow] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [data, setData] = useState();
  const [paginated, setPaginated] = useState([])
  const [page, setPage] = useState(0)
  const [offset, setOffset] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [pageCount, setPageCount] = useState(0)
  const [users, setUsers] = useState([]);
  const [notaryId, setNotaryId] = useState('');
  const [search, setSearch] = useState('')
  const [showApproved,setShowApproved] = useState(false)
  const [approvedData,setApprovedData] = useState('')


  const getUsers = () => {
    getNotaryList().then((response) => {
      if (response.status == 200) {
        setUsers(response.data.data);
        setPageCount(Math.ceil(response.data.data.length / itemsPerPage))
      }
    });
  };

 

  const handleDeactivate = (user) => {
    setData({
      id: user._id,
      status: user?.is_deactivated ? "activate" : "deactivate",
    });
    setShow(true);
  };

  const paginationHandler = () => {
    const endOffset = offset + itemsPerPage;
    let paginated = []
    if (search.length > 0) {
      let searchArr = users.filter((item) => item?.name?.toLowerCase().includes(search.toLowerCase()))
      setPageCount(Math.ceil(searchArr.length / itemsPerPage))
      paginated = searchArr.slice(offset, endOffset)
    }
    else {
      setPageCount(Math.ceil(users.length / itemsPerPage))
      paginated = users.slice(offset, endOffset)
    }
    setPaginated(paginated)
  }
  const onDataPassHandler = (newData) => {
    if (newData == 201) {
      getUsers()
    }
  }
  const handleSearch = (e) => {
    setSearch(e.target.value)
    setOffset(0)
    setPage(0)

  }

  const handleApproved = (user,isApproved) => {
    const payload = {
      "email": user,
      "isapproved":isApproved
    }
    setShowApproved(true)
    setApprovedData(payload)

    
  }
  const handlePageClick = (e) => {
    setPage(e.selected)
    const newOffset = (e.selected * itemsPerPage);
    setOffset(newOffset)
  }
  useEffect(() => {
    paginationHandler()
  }, [users, offset,search]);

  const editNotaryHandler = (id) => {
    setShowSuccess(true)
    setNotaryId(id)
  }
  const handleClose = () => {
    setShowSuccess(false)
    setShowApproved(false)
  }
const approvedNotaryAccount = () =>{

const payload = {
  "email": approvedData.email
} 
 notaryApproved(payload).then(response => {
      getUsers()
      if (response.status == 200) {
        toast.success(response.data.message)
      }
    }).catch(err => {
      toast.error(err.response.data.message)
    })
}

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <main class="content">
        <div>
          <a id="btn-toggle" href="#" class="sidebar-toggler break-point-sm">
            <i class="ri-menu-line ri-xl"></i>
          </a>
          {data && (
            <SimpleModal
              show={show}
              setShow={setShow}
              type="deactivate-notary"
              data={data}
              cb={getUsers}
            />
          )}
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="clearfix mt-3 mb-4">
                  <div class="float-start">
                    <h2 class="text-contact fw-bolder">Manage Notaries</h2>
                  </div>
                  {/* <div class="float-end">
                                        <nav aria-label="breadcrumb">
                                            <ol class="breadcrumb contact-bread">
                                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                                <li class="breadcrumb-item active" aria-current="page">Manage Notaries</li>
                                            </ol>
                                        </nav>
                                    </div> */}
                </div>
              </div>
            </div>
            <ApprovedNotary showApproved={showApproved} handleClose={handleClose} approvedNotaryAccount={approvedNotaryAccount} approvedData={approvedData}/>
            {showSuccess && (<EditNotary showSuccess={showSuccess} handleClose={handleClose} notaryId={notaryId} onDataPass={onDataPassHandler} />)}
            <div class="row">
              <div class="col-12">
                <div class="card border-0 manage-usr-text mb-5">
                  <div class="card-body">
                    <div class="search-user"><label>Search:<input type="search" class="search-input" placeholder="" aria-controls="table" onChange={handleSearch} /></label></div>
                    <div class="table-responsive">
                      <table class="table table-first text-nowrap" id="table">
                        <thead>
                          <tr>
                            <th>Sr no.</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                            <th>Type</th>
                            <th>Account Status</th>
                            <th> Status</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {paginated?.length !== 0 &&
                            paginated.map((user, i) => (
                              <tr>
                                <td>{((page + 1) * itemsPerPage - itemsPerPage) + i + 1}</td>
                                <td>{user.name}</td>
                                <td>{user.email}</td>
                                <td>{user.phoneNumber}</td>
                                <td>{user.serviceType}</td>
                                <td>
                                  {" "}
                                  <span
                                    class={
                                      !user.is_deactivated
                                        ? "badge bg-success"
                                        : "badge bg-danger"
                                    }
                                  >
                                    {user.is_deactivated
                                      ? "Deactivated"
                                      : "Active"}
                                  </span>
                                </td>
                                <td>
                                  {" "}
                                  <span
                                    class={
                                      user.is_approved
                                        ? "badge bg-success"
                                        : "badge bg-secondary"
                                    }
                                  >
                                    {user.is_approved
                                      ? "Approved"
                                      : "Pending"}
                                  </span>
                                </td>
                                <td>
                                  
                                  <Link class="me-2" title="view">
                                    <button
                                      onClick={() => handleDeactivate(user)}
                                      class="btn btn-light"
                                      type="button"
                                    >
                                      <ImUserMinus />
                                    </button>
                                  </Link>
                                  <Link
                                    to={`/notary-details/${user._id}`}
                                    class="me-2"
                                    title="view"
                                  >
                                    <button class="btn btn-light" type="button">
                                      <RiEyeFill />
                                    </button>

                                  </Link>
                                  <button class="btn btn-light" type="button" onClick={() => editNotaryHandler(user._id)}>
                                    <RiPencilFill />
                                  </button>                       
                                  {user.is_approved?
                                   <button className="btn btn-dark btn-sm ms-2 common-approve-rej common-approv " type="button" onClick={() => handleApproved(user.email,user.is_approved)}>
                                   <IoClose /> Unapprove
                                 </button>:<button className="btn btn-success btn-sm ms-2 common-approve-rej common-approv" type="button" onClick={() => handleApproved(user.email,user.is_approved)}>
                                   <FaCheck /> Approve
                                 </button>
                                 
                                  }
                                  
                                  {/* <a href="#modal-restriction" class="me-2" title="restricted" data-bs-toggle="modal" >
                                                                <button class="btn btn-light" type="button"><RiForbidFill /></button>
                                                            </a>
                                                            <a href="#modal-delete" data-bs-toggle="modal" title="delete">
                                                                <button class="btn btn-light" type="button"><RiDeleteBin3Fill />
                                                                </button>
                                                            </a> */}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {users.length > 10 &&
          <ReactPaginate
            breakLabel="..."
            breakClassName={'break'}
            nextLabel="next >"
            containerClassName={"pagination"}
            activeClassName={"active"}
            onPageChange={handlePageClick}
            forcePage={page}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< previous"

          />
        }
      </main>
    </>
  );
};

export default Notaries;
