import circleSvg from '../assets/images/circle.svg'
import standardImg from '../assets/images/standard-img.svg'
import timeSvg from '../assets/images/time.svg'
import TimeSlots from './TimeSlots';
import AppContext from 'context/appContext';
import moment from 'moment';
import { useContext, useState } from 'react';
import {Link, useLocation, useNavigate } from 'react-router-dom';
import { IoMdArrowRoundBack } from "react-icons/io";
const ServiceDetails = () => {
    const { state } = useLocation()
    const [selectedSlot, setSelectedSlot] = useState('')
    const [selectedDate, setSelectedDate] = useState('')
    const context = useContext(AppContext)
    let navigate = useNavigate()

    console.log("services", state)
    const onContinue = (e) => {
        e.preventDefault()
        console.log('date', selectedDate, selectedSlot);
        const dateMoment = moment(selectedDate);
        const timeMoment = moment(selectedSlot, "HH:mm a");

        // Set the hours, minutes, and AM/PM indicator on the date object using the set() function.

        dateMoment.set({ hour: timeMoment.get("hour"), minute: timeMoment.get("minute"), second: 0 });
        // Convert the moment.js object to a JavaScript Date object using the toDate() function.
        const dateObject = dateMoment.toDate()
        console.log(dateObject.toISOString());
        state.selectedDate = dateObject.toISOString()
        navigate('/service-form', { state: state })
    }
    return (
        <>
            <main className="content">
                <div>
                    <a id="btn-toggle" href="#" className="sidebar-toggler break-point-sm">
                        <i className="ri-menu-line ri-xl"></i>
                    </a>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="clearfix mt-3 mb-4">
                                    <div class="float-start">
                                        <Link to={"/booking-service"}>
                                            <p class="text fw-700 mb-0">
                                                <IoMdArrowRoundBack />
                                                Back
                                            </p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div class="container-fluid  text-black">
                        <div class="row">

                            <div className="col-md-12">
                                <div className="card border-0">
                                    <div className="card-body">
                                        <div className="row">

                                            <div class="col-md-1">
                                                <img src={context.img_uri + state.img} alt="service-image" class="imageSize" />
                                            </div>
                                            <div class="col-md-10">
                                                <div class="ms-lg-0">
                                                    <p class="small mb-0 fw-700 m-img">Mobile Notary X</p>
                                                    <h2 class="fw-900">{state.service_name}</h2>
                                                    <p className='mb-2'>{state.service_description}</p>

                                                    <div class="grid_Services new" dangerouslySetInnerHTML={{ __html: state.service_points }}>
                                                        {/* {state.service_points} */}
                                                        {/* <div class="col-lg-4 col-md-6">
                                    <p class="mb-3"><img src={circleSvg} alt="circle-image" class="me-2" /> Acknowledgement</p>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <p class="mb-3"><img src={circleSvg} alt="circle-image" class="me-2" /> Power of Attorney</p>

                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <p class="mb-3"><img src={circleSvg} alt="circle-image" class="me-2" />  I-9 Verification (Auth. Rep.)</p>

                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <p class="mb-3"><img src={circleSvg} alt="circle-image" class="me-2" /> Jurat</p>

                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <p class="mb-3"><img src={circleSvg} alt="circle-image" class="me-2" /> General Notary</p>

                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <p class="mb-3"><img src={circleSvg} alt="circle-image" class="me-2" /> Copy Certifications</p>

                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <p class="mb-3"><img src={circleSvg} alt="circle-image" class="me-2" /> Last Will and Testament</p>

                                </div> */}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    {/* <!-- Instructions --> */}

                    <div class="bg-violet mt-3">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12">
                                    <ol class="fw-700 ps-3 mb-0">
                                        <li class="mb-2">Please select a time and day on the calendar to book a notarization with one of our Mobile Notaries.</li>
                                        <li class="mb-2">Once you confirm your day and time, you must complete our pre notary questionnaire before you complete your booking.</li>
                                        <li class="mb-2">Depending the answers provided, we may contact you for further clarity regarding the appointment before we send
                                            out one of our Notaries.</li>
                                    </ol>
                                    <p class=" color fw-700">Please contact us if you have any questions prior to booking.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <!-- Select --> */}

                    <div class="container-fluid text-black">
                        <div class="row">
                            <div class="col-md-12">
                                <h4 class="fw-900">Select Date & Time</h4>
                            </div>

                            <form class="mt-3 select-form">
                                <div class="col-md-4">
                                    <div class="mb-4">
                                        <label for="date" class="form-label">Date</label>
                                        <input type="date" min={moment(new Date()).format("YYYY-MM-DD")} class="form-control" id="date" onChange={(e) => setSelectedDate(e.target.value)} />
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label mb-3">Time:
                                        <span class="ms-2 text-decoration-underline text-time">Duration: 30 min</span>
                                    </label>
                                    {/* <div class="row">
                                <div class="col-xl-2 col-lg-2 col-4">
                                    <div class="mb-3">
                                        <input type="radio" class="btn-check" name="options" id="option1" autocomplete="off"/>
                                            <label class="btn btn-secondary w-100" for="option1">12:00 am</label>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-lg-2 col-4">
                                    <div class="mb-3">
                                        <input type="radio" class="btn-check" name="options" id="option2" autocomplete="off"/>
                                            <label class="btn btn-secondary w-100" for="option2">12:00 am</label>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-lg-2 col-4">
                                    <div class="mb-3">
                                        <input type="radio" class="btn-check" name="options" id="option3" autocomplete="off"/>
                                            <label class="btn btn-secondary w-100" for="option3">12:00 am</label>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-lg-2 col-4">
                                    <div class="mb-3">
                                        <input type="radio" class="btn-check" name="options" id="option4" autocomplete="off"/>
                                            <label class="btn btn-secondary w-100" for="option4">12:00 am</label>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-lg-2 col-4">
                                    <div class="mb-3">
                                        <input type="radio" class="btn-check" name="options" id="option5" autocomplete="off"/>
                                            <label class="btn btn-secondary w-100" for="option5">12:00 am</label>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-lg-2 col-4">
                                    <div class="mb-3">
                                        <input type="radio" class="btn-check" name="options" id="option6" autocomplete="off"/>
                                            <label class="btn btn-secondary w-100" for="option6">12:00 am</label>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-lg-2 col-4">
                                    <div class="mb-3">
                                        <input type="radio" class="btn-check" name="options" id="option7" autocomplete="off"/>
                                            <label class="btn btn-secondary w-100" for="option7">12:00 am</label>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-lg-2 col-4">
                                    <div class="mb-3">
                                        <input type="radio" class="btn-check" name="options" id="option8" autocomplete="off"/>
                                            <label class="btn btn-secondary w-100" for="option8">12:00 am</label>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-lg-2 col-4">
                                    <div class="mb-3">
                                        <input type="radio" class="btn-check" name="options" id="option9" autocomplete="off"/>
                                            <label class="btn btn-secondary w-100" for="option9">12:00 am</label>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-lg-2 col-4">
                                    <div class="mb-3">
                                        <input type="radio" class="btn-check" name="options" id="option10" autocomplete="off"/>
                                            <label class="btn btn-secondary w-100" for="option10">12:00 am</label>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-lg-2 col-4">
                                    <div class="mb-3">
                                        <input type="radio" class="btn-check" name="options" id="option11" autocomplete="off"/>
                                            <label class="btn btn-secondary w-100" for="option11">12:00 am</label>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-lg-2 col-4">
                                    <div class="mb-3">
                                        <input type="radio" class="btn-check" name="options" id="option12" autocomplete="off"/>
                                            <label class="btn btn-secondary w-100" for="option12">12:00 am</label>
                                    </div>
                                </div>
                            </div> */}
                                    <TimeSlots startTime={10} endTime={19} slotDifference={0.5} onSelect={(value) => setSelectedSlot(value)} />
                                </div>

                                <div class="col-xl-2 col-md-4">
                                    <button class="btn btn-books py-3 w-100 " type='submit' onClick={onContinue}>Continue</button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default ServiceDetails;