import React, {useContext, useState } from "react";
import AppContext from 'context/appContext'
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from 'react-router-dom'
import { adminLogout } from 'services/apiServices'



const ApprovedNotary = (props) => {
  const { showApproved, handleClose,approvedNotaryAccount,approvedData} = props;

  const navigate = useNavigate()
  const context = useContext(AppContext)

  const confirmDelete = () => {
    handleClose();
    approvedNotaryAccount()
  };
  return (
    <Modal show={showApproved} onHide={handleClose} centered>
      <Modal.Header closeButton={true} className="border-0 mt-4 pt-0 pb-2">
      </Modal.Header>
      <Modal.Body className="text-center px-md-5 pt-0">
        <h4 className="text-red-sure">
          Are you sure you want to {approvedData.isapproved?"Unapprove":"Approve"} this account?
        </h4>
      </Modal.Body>
      <Modal.Footer className="border-0 d-flex justify-content-around pt-0   w-75 mx-auto mb-4 pb-0">
        <button
          type="submit"
          onClick={confirmDelete}
          className="btn btn-invite new next fw-bolder update-class "
        >
          Yes
        </button>
        <Button
          variant="secondary"
          onClick={handleClose}
          className="btn btn-closed new "
        >
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ApprovedNotary