import { useEffect, useState } from "react";
import { RiDeleteBin3Fill, RiEyeFill, RiForbidFill } from "react-icons/ri";
import { getUserList } from "services/apiServices";
import ReactPaginate from 'react-paginate';

const Users = () => {
    const [users, setUsers] = useState([])
    const [paginated, setPaginated] = useState([])
    const [page, setPage] = useState(0)
    const [offset, setOffset] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(12)
    const [pageCount, setPageCount] = useState(0)
    const [search, setSearch] = useState('')
    useEffect(() => {
        const getUsers = () => {
            getUserList().then(response => {
                if (response.status == 200) {
                    let data = response.data.data.filter((item) => item.serviceType == "individual")                 
                    setUsers(data)
                    setPageCount(Math.ceil(data.length / itemsPerPage))
                }
            })
        }
        getUsers()
    }, [])

    const handlePageClick = (e) => {
        setPage(e.selected)
        const newOffset = (e.selected * itemsPerPage);
        setOffset(newOffset)
    }

    const paginationHandler = () => {
        const endOffset = offset + itemsPerPage;
        let paginated = []
       
        if (search.length > 0) {
            let searchArr = users.filter((item) => item?.name?.toLowerCase().includes(search.toLowerCase()))
            setPageCount(Math.ceil(searchArr.length / itemsPerPage))
            paginated = searchArr.slice(offset, endOffset)
        }
        else {
            setPageCount(Math.ceil(users.length / itemsPerPage))
            paginated = users.slice(offset, endOffset)
        }
        setPaginated(paginated)
    }

    const handleSearch = (e) => {
        setSearch(e.target.value)
        setOffset(0)
        setPage(0)

    }

    useEffect(() => {
        paginationHandler()
    }, [users, offset,search]);


    return (
        <>
            <main class="content">
                <div>
                    <a id="btn-toggle" href="#" class="sidebar-toggler break-point-sm">
                        <i class="ri-menu-line ri-xl"></i>
                    </a>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="clearfix mt-3 mb-4">
                                    <div class="float-start">
                                        <h2 class="text-contact fw-bolder">Manage Users</h2>
                                    </div>
                                    <div class="float-end">
                                        <nav aria-label="breadcrumb">
                                            <ol class="breadcrumb contact-bread">
                                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                                <li class="breadcrumb-item active" aria-current="page">Manage Users</li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <div class="card border-0 manage-usr-text mb-5">
                                    <div class="card-body">
                                    <div class="search-user"><label>Search:<input type="search" class="search-input" placeholder="" aria-controls="table" onChange={handleSearch} /></label></div>
                                        <div class="table-responsive">
                                            <table class="table table-first text-nowrap" id="table">
                                                <thead>
                                                    <tr>
                                                        <th>Sr no.</th>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Phone Number</th>
                                                        <th>User Type</th>
                                                        <th>Account Status</th>
                                                        {/* <th>Action</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {paginated?.length !== 0 && paginated.map((user, i) =>
                                                        <tr>
                                                            <td>{((page + 1) * itemsPerPage - itemsPerPage) + i + 1}</td>
                                                            <td>{user.name}</td>
                                                            <td>{user.email}</td>
                                                            <td>{user.phoneNumber}</td>
                                                            <td>{user.serviceType}</td>
                                                            <td> <span class={user.is_approved ? "badge bg-success" : "badge bg-secondary"}>{user.is_approved ? "Verified" : "Not verified"}</span></td>
                                                            {/* <td>
                                                            <a href="#" class="me-2" title="view">
                                                                <button class="btn btn-light" type="button"><RiEyeFill /></button>
                                                            </a>
                                                            <a href="#modal-restriction" class="me-2" title="restricted" data-bs-toggle="modal" >
                                                                <button class="btn btn-light" type="button"><RiForbidFill /></button>
                                                            </a>
                                                            <a href="#modal-delete" data-bs-toggle="modal" title="delete">
                                                                <button class="btn btn-light" type="button"><RiDeleteBin3Fill />
                                                                </button>
                                                            </a>
                                                        </td> */}
                                                        </tr>

                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {users.length > 10 &&
                    <ReactPaginate
                        breakLabel="..."
                        breakClassName={'break'}
                        nextLabel="next >"
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        onPageChange={handlePageClick}
                        forcePage={page}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="< previous"

                    />
                }
            </main>
        </>
    );
}

export default Users;