import Logo from 'assets/images/logo.svg'
import AppContext from 'context/appContext'
import { useContext, useRef, useState } from 'react'
import { FiLogOut } from 'react-icons/fi'
import { RiAppsFill, RiLayoutRowFill, RiTeamFill } from 'react-icons/ri'
import { IoIosBusiness } from "react-icons/io";
import { MdContactSupport } from "react-icons/md";
import { FaPencilRuler } from 'react-icons/fa'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { GrTransaction } from "react-icons/gr";
import { IoDocumentText } from "react-icons/io5";
import LogoutAccount from './LogoutAccount'

const Sidebar = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const context = useContext(AppContext)
    const [showLogoutAccount,setShowLogoutAccount] = useState(false)
    const refs = {
        serviceMenu: useRef(),
        serviceSubMenu: useRef(),
        notaryMenu: useRef(),
        notarySubMenu: useRef(),
        staticMenu: useRef(),
        staticSubMenu: useRef(),
    };


    const toggleMenu = (menuRef, submenuRef) => {
        const menu = menuRef.current;
        const submenu = submenuRef.current;

        if (!menu.classList.contains('open')) {
            menu.classList.add('open');
            submenu.style.display = 'block';
        } else {
            menu.classList.remove('open');
            submenu.style.display = 'none';
        }
    }
    const handleMenu = (type) => {
        switch (type) {
            case "service":
                toggleMenu(refs.serviceMenu, refs.serviceSubMenu)
                break;
            case "notary":
                toggleMenu(refs.notaryMenu, refs.notarySubMenu)
                break;
            case "static":
                toggleMenu(refs.staticMenu, refs.staticSubMenu);
            default:
                break
        }

    }

    const logout = () => {
        setShowLogoutAccount(true)
    }
    const handleClose = () =>{
        setShowLogoutAccount(false)
    }
    return (
        <>
            <aside id="sidebar" class="sidebar break-point-sm has-bg-image">
                {/* <a id="btn-collapse" class="sidebar-collapser"><i class="ri-arrow-left-s-line"></i></a> */}
                <div class="image-wrapper">
                    <img src="assets/images/sidebar-bg.jpg" alt="sidebar background" />
                </div>
                <div class="sidebar-layout">
                    <div class="sidebar-header">
                        <div class="pro-sidebar-logo">
                            <Link to='/dashboard'>
                                <img src={Logo} alt="logo" class="img-fluid" width="200" />
                            </Link>
                        </div>
                    </div>
                    <LogoutAccount showLogoutAccount={showLogoutAccount} handleClose={handleClose}/>
                    <div class="sidebar-content">
                        <nav class="menu open-current-submenu">
                            <ul>
                                <li class="menu-header"><span> ADMIN </span></li>
                                <li class={location.pathname == '/dashboard' ? "menu-item active" : "menu-item"}>
                                    <Link to='/dashboard'>
                                        <span class="menu-icon">
                                            <RiAppsFill />
                                        </span>
                                        <span class="menu-title">Dashboard</span>
                                    </Link>
                                </li>

                                <li class={location.pathname == '/users' ? "menu-item active" : "menu-item"}>
                                    <Link to='/users'>
                                        <span class="menu-icon">
                                            <RiTeamFill />
                                        </span>
                                        <span class="menu-title">Manage Users</span>
                                    </Link>
                                </li>
                                <li class={location.pathname == '/manage-business' ? "menu-item active" : "menu-item"}>
                                    <Link to='/manage-business'>
                                        <span class="menu-icon">
                                        <IoIosBusiness />
                                        </span>
                                        <span class="menu-title">Manage Business</span>
                                    </Link>
                                </li>


                                <li ref={refs.notaryMenu} class="menu-item sub-menu" onClick={() => handleMenu('notary')}>
                                    <Link>
                                        <span class="menu-icon">
                                            <FaPencilRuler />
                                        </span>
                                        <span class="menu-title">Manage Notary</span>
                                    </Link>
                                    <div ref={refs.notarySubMenu} class="sub-menu-list" onClick={(e) => e.stopPropagation()}>
                                        <ul>
                                            <li class="menu-item">
                                                <Link to='/notaries'>
                                                    <span class="menu-title">Notaries</span>
                                                </Link>
                                            </li>

                                        </ul>
                                    </div>
                                </li>
                                <li class={location.pathname == '/jobs' ? "menu-item active" : "menu-item"}>
                                    <Link to='/jobs'>
                                        <span class="menu-icon">
                                            <IoDocumentText />
                                        </span>
                                        <span class="menu-title">Manage Jobs</span>
                                    </Link>
                                </li>
                                <li class={location.pathname == '/prices' ? "menu-item active" : "menu-item"}>
                                    <Link to='/prices'>
                                        <span class="menu-icon">
                                            <RiTeamFill />
                                        </span>
                                        <span class="menu-title">Manage Flat Price</span>
                                    </Link>
                                </li>
                                <li class={location.pathname == '/servicePrices' ? "menu-item active" : "menu-item"}>
                                    <Link to='/servicePrices'>
                                        <span class="menu-icon">
                                            <RiTeamFill />
                                        </span>
                                        <span class="menu-title">Manage Service Price</span>
                                    </Link>
                                </li>

                                <li ref={refs.serviceMenu} class="menu-item sub-menu" onClick={() => handleMenu('service')}>
                                    <Link>
                                        <span class="menu-icon">
                                            <RiLayoutRowFill />
                                        </span>
                                        <span class="menu-title">Manage Services</span>
                                    </Link>
                                    <div ref={refs.serviceSubMenu} class="sub-menu-list" onClick={(e) => e.stopPropagation()}>
                                        <ul>
                                            <li class="menu-item">
                                                <Link to='/services'>
                                                    <span class="menu-title">Services</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>

                                <li ref={refs.staticMenu} class="menu-item sub-menu" onClick={() => handleMenu('static')}>
                                    <Link>
                                        <span class="menu-icon">
                                            <FaPencilRuler />
                                        </span>
                                        <span class="menu-title">Manage Static Content</span>
                                    </Link>
                                    <div ref={refs.staticSubMenu} class="sub-menu-list" onClick={(e) => e.stopPropagation()}>
                                        <ul>
                                            <li class="menu-item">
                                                <Link to='/about-us'>
                                                    <span class="menu-title">About Us</span>
                                                </Link>
                                            </li>
                                            <li class="menu-item">
                                                <Link to='/faqs'>
                                                    <span class="menu-title">Faq's</span>
                                                </Link>
                                            </li>
                                            <li class="menu-item">
                                                <Link to='/pricing'>
                                                    <span class="menu-title">Pricing & Cancellation</span>
                                                </Link>
                                            </li>
                                            <li class="menu-item">
                                                <Link to='/privacy'>
                                                    <span class="menu-title">Privacy Policy</span>
                                                </Link>
                                            </li>
                                            <li class="menu-item">
                                                <Link to='/terms'>
                                                    <span class="menu-title">Terms & Condition</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li class={location.pathname == '/manage-contacts' ? "menu-item active" : "menu-item"}>
                                    <Link to='/manage-contacts'>
                                        <span class="menu-icon">
                                            <GrTransaction />
                                        </span>
                                        <span class="menu-title">Manage Contacts</span>
                                    </Link>
                                </li>

                                <li class={location.pathname == '/job-transactions' ? "menu-item active" : "menu-item"}>
                                    <Link to='/job-transactions'>
                                        <span class="menu-icon">
                                            <GrTransaction />
                                        </span>
                                        <span class="menu-title">Manage Transactions</span>
                                    </Link>
                                </li>

                                <li class={location.pathname == '/notary-withdrawls' ? "menu-item active" : "menu-item"}>
                                    <Link to='/notary-withdrawls'>
                                        <span class="menu-icon">
                                            <GrTransaction />
                                        </span>
                                        <span class="menu-title">Notary Withdrawls</span>
                                    </Link>
                                </li>
                                <li class={location.pathname == '/help-and-support' ? "menu-item active" : "menu-item"}>
                                    <Link to='/help-and-support'>
                                        <span class="menu-icon">
                                        <MdContactSupport />
                                        </span>
                                        <span class="menu-title">Help & Support</span>
                                    </Link>
                                </li>

                                {/* <li class="menu-item">
                                    <a href="service-management.html">
                                        <span class="menu-icon">
                                            <i class="ri-layout-row-fill"></i>
                                        </span>
                                        <span class="menu-title">Service Management</span>
                                    </a>
                                </li>

                                <li class="menu-item">
                                    <a href="job-management.html">
                                        <span class="menu-icon">
                                            <i class="ri-briefcase-2-fill"></i>
                                        </span>
                                        <span class="menu-title">Job Management</span>
                                    </a>
                                </li>

                                <li class="menu-item">
                                    <a href="manage-transactions.html">
                                        <span class="menu-icon">
                                            <i class="ri-wallet-2-fill"></i>
                                        </span>
                                        <span class="menu-title">Manage Transaction</span>
                                    </a>
                                </li>

                                <li class="menu-item sub-menu">
                                    <a href="#">
                                        <span class="menu-icon">
                                            <i class="ri-question-fill"></i>
                                        </span>
                                        <span class="menu-title">Manage Help Section</span>
                                    </a>
                                    <div class="sub-menu-list">
                                        <ul>
                                            <li class="menu-item">
                                                <a href="user-tickets.html">
                                                    <span class="menu-title">User Tickets</span>
                                                </a>
                                            </li>
                                            <li class="menu-item">
                                                <a href="closed-tickets.html">
                                                    <span class="menu-title">Closed Tickets</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="menu-item sub-menu">
                                    <a href="#">
                                        <span class="menu-icon">
                                            <i class="ri-file-copy-2-fill"></i>
                                        </span>
                                        <span class="menu-title">Manage Static</span>
                                    </a>
                                    <div class="sub-menu-list">
                                        <ul>
                                            <li class="menu-item">
                                                <a href="faq.html">
                                                    <span class="menu-title">FAQ's</span>
                                                </a>
                                            </li>
                                            <li class="menu-item">
                                                <a href="about.html">
                                                    <span class="menu-title">About us</span>
                                                </a>
                                            </li>
                                            <li class="menu-item">
                                                <a href="terms.html">
                                                    <span class="menu-title">Terms & Conditions</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>*/}
                                <li class="menu-item" onClick={logout}>
                                    <a >
                                        <span class="menu-icon">
                                            <FiLogOut />
                                        </span>
                                        <span class="menu-title">Logout</span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </aside>
        </>
    );
}

export default Sidebar;