import Logo from "assets/images/logo.svg";
import React, { useState } from "react";
import axios from "axios";
import AppContext from "context/appContext";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { adminLogin } from "services/apiServices";
import { BiSolidHide, BiShow } from "react-icons/bi";

const AdminLogin = () => {
  const context = useContext(AppContext);
  const [showPassword, setShowPassword] = useState(false);
  let navigate = useNavigate();
  let {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const formsubmit = (data) => {
    adminLogin(data)
      .then((response) => {
        if (response.status == 200) {
          console.log("cehceing res", response?.data);
          localStorage.setItem("token", response?.data?.token);
          localStorage.setItem("userId", response?.data?.data._id);
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${response?.data?.token}`;
          context.setSession(response?.data?.data?.session);
          localStorage.setItem("session", response?.data?.data?.session);
          navigate("/dashboard");
          toast.success("loggedin successfull.");
        }
      })
      .catch((err) => {
        console.log("cehceing err", err);
        toast.error(err?.response?.data?.message);
      });
  };
  return (
    <>
      <div class="container-fluid">
        <div class="row align-items-center justify-content-center min-vh">
          <div class="col-md-7 col-lg-6 col-xl-4 px-media-0">
            <div class="card border-0 shadow-login">
              <div class="card-body p-md-5">
                <div class="logo pb-4">
                  <a href="#">
                    <img src={Logo} alt="logo" class="mx-auto d-block" />
                  </a>
                </div>

                <h3 class="fw-bolder text-center text-blue mb-0">
                  Login to your account
                </h3>
                <p class="text-center">Please enter your details</p>

                <form class="fc-size" onSubmit={handleSubmit(formsubmit)}>
                  <div class="mb-4">
                    <label for="email" class="form-label fw-bolder">
                      Email
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      placeholder="Enter Email"
                      id="email"
                      {...register("email", {
                        required: "Please enter email.",
                        pattern: {
                          value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                          message: "Invalid email",
                        },
                      })}
                    />
                    {errors.email && (
                      <span className="error">{errors.email.message}</span>
                    )}
                  </div>

                  <div class="mb-2 position-relative">
                    <label for="password" class="form-label fw-bolder">
                      Password
                    </label>
                    <input
                      type={showPassword ? "text" : "password"}
                      class="form-control"
                      placeholder="Enter Password"
                      id="exampleInputPassword"
                      {...register("password", {
                        required: "Password is required.",
                      })}
                    />
                    <div className='password-hide-show' onClick={togglePasswordVisibility}>{showPassword ? <BiShow /> : <BiSolidHide />} </div>
                    {errors.password && (
                      <span className="error">{errors.password.message}</span>
                    )}
                  </div>

                  <div class="text-end mb-4">
                    <a
                      href="#"
                      class="text-black fw-bolder text-decoration-none"
                    >
                      Forgot your password?{" "}
                    </a>
                  </div>

                  <button type="submit" class="btn btn-linear btn-block w-100">
                    Login
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminLogin;
