import Faq from "components/Faqs/addFaq";
import EditFaq from "components/Faqs/editFaq";
import FaqListing from "components/Faqs/faqList";
import AddFlatPrice from "components/Flat Prices/addFlatPrice";
import EditFlatPrice from "components/Flat Prices/editFlatPrice";
import FlatPricesList from "components/Flat Prices/flatPricesList";
import ServicePricesList from "components/job services/servicePrices";
import ServiceDetails from "components/job services/serviceDetails";
import AboutUs from "components/about-us";
import Dashboard from "components/dashboard";
import AddService from "components/job services/add-service";
import EditService from "components/job services/edit-service";
import ServicesList from "components/job services/serviceslist";
import JobDetails from "components/jobDetails";

import AllJobs from "components/jobList";
import JobTransactions from "components/jobTransactions";
import AdminLogin from "components/login";
import Notaries from "components/notaries/notaries";
import NotaryDetails from "components/notaries/notaryDetails";
import PrincingAndCancellation from "components/pricing-cancellation";
import Privacy from "components/privacyPolicy";
import Terms from "components/terms-condition";
import Users from "components/users/users";
import AppContext from "context/appContext";
import { useContext } from "react";
import { Route, Routes,Navigate } from "react-router-dom";
import ManageService from "components/job services/manage-service";
import ManageRealEstateService from "components/job services/manage-real-estate-service";
import NotaryTransaction from "components/transactions/NotaryTransaction";
import ContactForms from "components/contactForm";
import BookingJob from "components/addBooking/bookingJob/BookingJob";
import ServiceForm from "pages/ServiceForm";
import ServiceDetailsForJob from "pages/service-details";
import HelpAndSupport from "components/helpAndSupport/HelpAndSupport";
import ManageBusiness from "components/manageBusiness/ManageBusiness";

const AppRouting = () => {
  const context = useContext(AppContext);
  console.log(context, "sssss");
  const token = localStorage.getItem("token");
  


  return (
    <>
      <Routes>
        {/* {(context.session !== null && context.session !== "") ? */}
        {token !== null && token !== "" ? (
          <>
          <Route path="/" element={<Navigate to={'/dashboard'} />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/users" element={<Users />} />
            <Route path="/manage-business" element={<ManageBusiness />} />
            <Route path="/notaries" element={<Notaries />} />
            <Route path="/notary-details/:id" element={<NotaryDetails />} />
            <Route path="/services" element={<ServicesList />} />
            <Route path="/prices" element={<FlatPricesList />} />
            <Route path="/servicePrices" element={<ServicePricesList />} />
            <Route path="/booking-service" element={<BookingJob />} />
            <Route path="/service-form" element={<ServiceForm />} />
            <Route path="/service/:slug" element={<ServiceDetailsForJob />} />
            <Route path="/add-price" element={<AddFlatPrice />} />
            <Route path="/edit-price/:id" element={<EditFlatPrice />} />
            <Route path="/jobs" element={<AllJobs />} />
            <Route
              path="/manage-service-price/:id"
              element={<ManageService />}
            />
            <Route
              path="/manage-real-estate-service/:id"
              element={<ManageRealEstateService />}
            />
            <Route path="/job-details/:id" element={<JobDetails />} />
            <Route
              path="/service-price-details/:id"
              element={<ServiceDetails />}
            />
            <Route path="/job-transactions" element={<JobTransactions />} />
            <Route path="/edit-service/:id" element={<EditService />} />
            <Route path="/faqs" element={<FaqListing />} />
            <Route path="/add-faq" element={<Faq />} />
            <Route path="/edit-faq/:id" element={<EditFaq />} />
            <Route path="/pricing" element={<PrincingAndCancellation />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/notary-withdrawls" element={<NotaryTransaction />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/manage-contacts" element={<ContactForms />} />
            <Route path="/help-and-support" element={<HelpAndSupport />} />
          </>
        ) : (
          <>
            <Route path="*" element={<AdminLogin />} />
          </>
        )}
      </Routes>
    </>
  );
};

export default AppRouting;
